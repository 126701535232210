import {
  POST_FH_NON_EXEMPT_RECEIPT_SUCCESS,
  POST_FH_NON_EXEMPT_RECEIPT_FAILURE,
  POST_FH_EXEMPT_RECEIPT_SUCCESS,
  POST_FH_EXEMPT_RECEIPT_FAILURE,
  CLEAR_FH_NON_EXEMPT_RECEIPT_ITEMS,
  CLEAR_FH_EXEMPT_RECEIPT_ITEMS,
  PATCH_FORM_REQUEST,
  PATCH_FORM_SUCCESS,
  PATCH_FORM_FAILURE,
  AMEND_FORM_REQUEST,
  AMEND_FORM_SUCCESS,
  AMEND_FORM_FAILURE,
} from './firstHandlerTypes';

import {
  // fetchFirstHandlerFormsPackages,
  selectCurrentFHFormsPackage,
  // selectCurrentFHForm,
  fetchForm,
} from '../../redux';

import history from '@iso/lib/helpers/history';
import { getAuthClient } from '../../library/drupal/DrupalAuth2';

const auth = getAuthClient();

export const postFirstHandlerNonExemptReceiptItem = (data) => {
  const url = `/jsonapi/node/fh_non_exempt_receipt`;
  const options = {
    method: 'POST',
    headers: new Headers({
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
    mode: 'cors',
    body: JSON.stringify(data),
  };
  return async (dispatch) => {
    try {
      const response = await auth.fetchWithAuthentication(url, options);
      if (response.ok) {
        const data = await response.json();
        dispatch(postFirstHandlerNonExemptReceiptItemSuccess(data.data));
      } else {
        throw new Error(`message: ${response.status}`);
      }
    } catch (error) {
      const errorMsg = error.message;
      dispatch(postFirstHandlerNonExemptReceiptItemFailure(errorMsg));
    }
  };
};

export const postFirstHandlerExemptReceiptItem = (data) => {
  const url = `/jsonapi/node/fh_exempt_receipt`;
  const options = {
    method: 'POST',
    headers: new Headers({
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
    mode: 'cors',
    body: JSON.stringify(data),
  };
  return async (dispatch) => {
    try {
      const response = await auth.fetchWithAuthentication(url, options);
      if (response.ok) {
        const data = await response.json();
        dispatch(postFirstHandlerExemptReceiptItemSuccess(data.data));
      } else {
        throw new Error(`message: ${response.status}`);
      }
    } catch (error) {
      const errorMsg = error.message;
      dispatch(postFirstHandlerExemptReceiptItemFailure(errorMsg));
    }
  };
};

export const postFirstHandlerReportFileItem = (file, fileName) => {
  // Maintaining this as a redux action, however, not using redux state.  Just return the file upload response
  const url = `/jsonapi/node/first_handler_report/field_fh_report_pdf`;
  const options = {
    method: 'POST',
    headers: new Headers({
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/octet-stream',
      'Content-Disposition': `file; filename="${fileName}"`,
    }),
    mode: 'cors',
    body: file,
  };
  return async (dispatch) => {
    //dispatch(postExemptionOrganicFileItemRequest());
    try {
      const response = await auth.fetchWithAuthentication(url, options);
      if (response.ok) {
        //dispatch(postExemptionOrganicFileItemSuccess(JSON.stringify(response)));
        //console.log('FILE SUCCESS', response);
        const data = await response.json();
        return data.data;
      } else {
        //console.log('FILE FAILURE', response);
        //dispatch(postExemptionOrganicFileItemFailure('An error has occured.'));
        return false;
      }
    } catch (error) {
      //console.log('FILE ERROR');
      //const errorMsg = error.message;
      //dispatch(postExemptionOrganicFileItemFailure(errorMsg));
      return false;
    }
  };
};

export const patchFirstHandlerReport = (
  formID,
  data,
  nonExemptOrphans,
  exemptOrphans
) => {
  if (formID !== null) {
    const url = `/jsonapi/node/first_handler_report/${formID}`;
    const options = {
      method: 'PATCH',
      headers: new Headers({
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
      body: JSON.stringify(data),
    };
    return async (dispatch) => {
      dispatch(patchFirstHandlerReportRequest());
      try {
        const response = await auth.fetchWithAuthentication(url, options);
        if (response.ok) {
          dispatch(patchFirstHandlerReportSuccess(JSON.stringify(response)));
          cleanUpFirstHandlerNonExemptReceiptOrphans(nonExemptOrphans);
          cleanUpFirstHandlerExemptReceiptOrphans(exemptOrphans);
          //const data = await response.json();
          // let companyUID = null;
          // try {
          //   companyUID = localStorage.getItem('company_uid');
          // } catch {
          //   //throw new Error()
          // }
          // let seasons = null;
          // try {
          //   seasons = JSON.parse(localStorage.getItem('seasons'));
          // } catch {
          //   //throw new error
          // }
          // await dispatch(fetchFirstHandlerFormsPackages(companyUID, seasons));
          history.push(
            `/dashboard/formCertification/first_handler_report/${formID}`
          );
        } else {
          dispatch(patchFirstHandlerReportFailure('An error has occured.'));
        }
      } catch (error) {
        const errorMsg = error.message;
        dispatch(patchFirstHandlerReportFailure(errorMsg));
      }
    };
  }
};

export const patchDraftFHReport = (
  formID,
  data,
  nonExemptOrphans,
  exemptOrphans
) => {
  if (formID !== null) {
    const url = `/jsonapi/node/first_handler_report/${formID}`;
    const options = {
      method: 'PATCH',
      headers: new Headers({
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
      body: JSON.stringify(data),
    };
    return async (dispatch) => {
      dispatch(patchFirstHandlerReportRequest());
      try {
        const response = await auth.fetchWithAuthentication(url, options);
        if (response.ok) {
          dispatch(patchFirstHandlerReportSuccess(JSON.stringify(response)));
          cleanUpFirstHandlerNonExemptReceiptOrphans(nonExemptOrphans);
          cleanUpFirstHandlerExemptReceiptOrphans(exemptOrphans);
          //const data = await response.json();
          // let companyUID = null;
          // try {
          //   companyUID = localStorage.getItem('company_uid');
          // } catch {
          //   //throw new Error()
          // }
          // let seasons = null;
          // try {
          //   seasons = JSON.parse(localStorage.getItem('seasons'));
          // } catch {
          //   //throw new error
          // }
          // await dispatch(fetchFirstHandlerFormsPackages(companyUID, seasons));
          await dispatch(selectCurrentFHFormsPackage(formID));
          //await dispatch(selectCurrentFHForm(formID));
          await dispatch(fetchForm(formID, 'first_handler_report'));
          return 'draftSuccess';
          //Since it's a draft don't redirect
          // history.push(
          //   `/dashboard/formCertification/first_handler_report/${formID}`
          // );
        } else {
          dispatch(patchFirstHandlerReportFailure('An error has occured.'));
          return 'draftFailure';
        }
      } catch (error) {
        const errorMsg = error.message;
        dispatch(patchFirstHandlerReportFailure(errorMsg));
      }
    };
  }
};

export const amendFirstHandlerReport = (
  formID,
  currentFormsPackageID,
  data
) => {
  if (formID !== null) {
    const url = `/jsonapi/node/first_handler_report`;
    const options = {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
      body: JSON.stringify(data),
    };
    return async (dispatch) => {
      dispatch(amendFormRequest());
      try {
        const response = await auth.fetchWithAuthentication(url, options);
        if (response.ok) {
          dispatch(amendFormSuccess(JSON.stringify(response)));
          const data = await response.json();
          const newFormID = data.data.id;
          await dispatch(
            addFirstHandlerReportToPackage(newFormID, currentFormsPackageID)
          );
          // let companyUID = null;
          // try {
          //   companyUID = localStorage.getItem('company_uid');
          // } catch {
          //   //throw new Error()
          // }
          // let seasons = null;
          // try {
          //   seasons = JSON.parse(localStorage.getItem('seasons'));
          // } catch {
          //   //throw new error
          // }
          // await dispatch(fetchFirstHandlerFormsPackages(companyUID, seasons));
          history.push(
            `/dashboard/formCertification/first_handler_report/${newFormID}`
          );
        } else {
          dispatch(amendFormFailure('An error has occured.'));
        }
      } catch (error) {
        const errorMsg = error.message;
        dispatch(amendFormFailure(errorMsg));
      }
    };
  }
};

export const addFirstHandlerReportToPackage = (formID, packageID) => {
  if (formID !== null && packageID !== null) {
    let data = {};
    data = {
      data: [
        {
          type: 'node--first_handler_report',
          id: formID,
        },
      ],
    };
    const url = `/jsonapi/node/fh_reporting_forms_package/${packageID}/relationships/field_fh_report`; //POST to a relationship resource will ADD the new node to the multi-value field.  Need to send as array.  PATCH will REPLACE existing field data.
    const options = {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
      body: JSON.stringify(data),
    };
    return async (dispatch) => {
      try {
        const response = await auth.fetchWithAuthentication(url, options);
        if (response.ok) {
          //console.log('ADD TO PACKAGE OK');
        } else {
          dispatch(amendFormFailure('An error has occured.'));
        }
      } catch (error) {
        const errorMsg = error.message;
        dispatch(amendFormFailure(errorMsg));
      }
    };
  }
};

export const cleanUpFirstHandlerNonExemptReceiptOrphans = (
  nonExemptOrphans
) => {
  //console.log('NON EX ORPHANS', nonExemptOrphans);
  if (nonExemptOrphans.length > 0) {
    nonExemptOrphans.forEach((nonExemptOrphan) => {
      deleteNonExemptReceiptOrphan(nonExemptOrphan);
    });
  }
};

export const deleteNonExemptReceiptOrphan = async (orphan) => {
  const url = `/jsonapi/node/fh_non_exempt_receipt/${orphan.id}`;
  const options = {
    method: 'DELETE',
    headers: new Headers({
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
    mode: 'cors',
    body: '',
  };

  try {
    const response = await auth.fetchWithAuthentication(url, options);
    if (response.ok) {
    } else {
    }
  } catch (error) {
    console.log('catch error');
  }
};

export const cleanUpFirstHandlerExemptReceiptOrphans = (exemptOrphans) => {
  //console.log('EX ORPHANS', exemptOrphans);
  if (exemptOrphans.length > 0) {
    exemptOrphans.forEach((exemptOrphan) => {
      deleteExemptReceiptOrphan(exemptOrphan);
    });
  }
};

export const deleteExemptReceiptOrphan = async (orphan) => {
  const url = `/jsonapi/node/fh_exempt_receipt/${orphan.id}`;
  const options = {
    method: 'DELETE',
    headers: new Headers({
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
    mode: 'cors',
    body: '',
  };

  try {
    const response = await auth.fetchWithAuthentication(url, options);
    if (response.ok) {
    } else {
    }
  } catch (error) {
    console.log('catch error');
  }
};

export const postFirstHandlerNonExemptReceiptItemSuccess = (postResult) => {
  return {
    type: POST_FH_NON_EXEMPT_RECEIPT_SUCCESS,
    payload: postResult,
  };
};

export const postFirstHandlerNonExemptReceiptItemFailure = (error) => {
  return {
    type: POST_FH_NON_EXEMPT_RECEIPT_FAILURE,
    payload: error,
  };
};

export const clearFirstHandlerNonExemptReceiptItems = () => {
  return {
    type: CLEAR_FH_NON_EXEMPT_RECEIPT_ITEMS,
  };
};

export const postFirstHandlerExemptReceiptItemSuccess = (postResult) => {
  return {
    type: POST_FH_EXEMPT_RECEIPT_SUCCESS,
    payload: postResult,
  };
};

export const postFirstHandlerExemptReceiptItemFailure = (error) => {
  return {
    type: POST_FH_EXEMPT_RECEIPT_FAILURE,
    payload: error,
  };
};

export const clearFirstHandlerExemptReceiptItems = () => {
  return {
    type: CLEAR_FH_EXEMPT_RECEIPT_ITEMS,
  };
};

export const patchFirstHandlerReportRequest = () => {
  return {
    type: PATCH_FORM_REQUEST,
  };
};

export const patchFirstHandlerReportSuccess = (success) => {
  return {
    type: PATCH_FORM_SUCCESS,
    payload: success.data,
  };
};

export const patchFirstHandlerReportFailure = (error) => {
  return {
    type: PATCH_FORM_FAILURE,
    payload: error,
  };
};

export const amendFormRequest = () => {
  return {
    type: AMEND_FORM_REQUEST,
  };
};

export const amendFormSuccess = (amendResult) => {
  return {
    type: AMEND_FORM_SUCCESS,
    payload: amendResult,
  };
};

export const amendFormFailure = (error) => {
  return {
    type: AMEND_FORM_FAILURE,
    payload: error,
  };
};
