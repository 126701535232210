import {
  FETCH_CONTACTS_REQUEST,
  FETCH_CONTACTS_SUCCESS,
  FETCH_CONTACTS_FAILURE,
  CHANGE_CONTACT,
  CREATE_CONTACT_REQUEST,
  CREATE_CONTACT_SUCCESS,
  CREATE_CONTACT_FAILURE,
  PATCH_CONTACT_REQUEST,
  PATCH_CONTACT_SUCCESS,
  PATCH_CONTACT_FAILURE,
} from './companyContactsTypes';

const initialState = {
  loading: false,
  contacts: [],
  selectedID: null,
  error: '',
};

const companyContactsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONTACTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CONTACTS_SUCCESS:
      return {
        ...state,
        loading: false,
        contacts: action.payload,
        error: '',
      };
    case FETCH_CONTACTS_FAILURE:
      return {
        ...state,
        loading: false,
        contacts: [],
        selectedID: null,
        error: action.payload,
      };
    case CHANGE_CONTACT:
      return {
        ...state,
        selectedID: action.id,
      };
    case CREATE_CONTACT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case CREATE_CONTACT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case PATCH_CONTACT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PATCH_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case PATCH_CONTACT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default companyContactsReducer;
