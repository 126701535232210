import {
  FETCH_CONTACTS_REQUEST,
  FETCH_CONTACTS_SUCCESS,
  FETCH_CONTACTS_FAILURE,
  CHANGE_CONTACT,
  CREATE_CONTACT_REQUEST,
  CREATE_CONTACT_SUCCESS,
  CREATE_CONTACT_FAILURE,
  PATCH_CONTACT_REQUEST,
  PATCH_CONTACT_SUCCESS,
  PATCH_CONTACT_FAILURE,
} from './companyContactsTypes';

import { getAuthClient } from '../../library/drupal/DrupalAuth2';

const auth = getAuthClient();

export const fetchContacts = (companyUID) => {
  const url = `/jsonapi/node/fh_producer_contact?filter[contact-filter][condition][path]=field_first_handler.id&filter[contact-filter][condition][value]=${companyUID}&page[limit]=1500`;
  const options = {
    method: 'GET',
    headers: new Headers({
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
    mode: 'cors',
  };
  return (dispatch) => {
    dispatch(fetchContactsRequest);
    auth
      .fetchWithAuthentication(url, options)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const contacts = data.data;
        dispatch(fetchContactsSuccess(contacts));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(fetchContactsFailure(errorMsg));
      });
  };
};

export const createContact = (data) => {
  const url = `/jsonapi/node/fh_producer_contact`;
  const options = {
    method: 'POST',
    headers: new Headers({
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
    mode: 'cors',
    body: JSON.stringify(data),
  };
  return async (dispatch) => {
    dispatch(createContactRequest());
    try {
      const response = await auth.fetchWithAuthentication(url, options);
      if (response.ok) {
        dispatch(createContactSuccess(JSON.stringify(response)));
        const data = await response.json();
        const newContactID = data.data.id;
        dispatch(changeContact(newContactID));
      } else {
        dispatch(createContactFailure('An error has occured.'));
      }
    } catch (error) {
      const errorMsg = error.message;
      dispatch(createContactFailure(errorMsg));
    }
  };
};

export const patchContact = (contactUID, data) => {
  const url = `/jsonapi/node/fh_producer_contact/${contactUID}`;
  const options = {
    method: 'PATCH',
    headers: new Headers({
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
    mode: 'cors',
    body: JSON.stringify(data),
  };
  return async (dispatch) => {
    dispatch(patchContactRequest());
    try {
      const response = await auth.fetchWithAuthentication(url, options);
      if (response.ok) {
        dispatch(patchContactSuccess(JSON.stringify(response)));
      } else {
        dispatch(patchContactFailure('An error has occured.'));
      }
    } catch (error) {
      const errorMsg = error.message;
      dispatch(patchContactFailure(errorMsg));
    }
  };
};

export const fetchContactsRequest = () => {
  return {
    type: FETCH_CONTACTS_REQUEST,
  };
};

export const fetchContactsSuccess = (contacts) => {
  return {
    type: FETCH_CONTACTS_SUCCESS,
    payload: contacts,
  };
};

export const fetchContactsFailure = (error) => {
  return {
    type: FETCH_CONTACTS_FAILURE,
    payload: error,
  };
};

export const changeContact = (id) => {
  return {
    type: CHANGE_CONTACT,
    id: id,
  };
};

export const createContactRequest = () => {
  return {
    type: CREATE_CONTACT_REQUEST,
  };
};

export const createContactSuccess = (createResult) => {
  return {
    type: CREATE_CONTACT_SUCCESS,
    payload: createResult,
  };
};

export const createContactFailure = (error) => {
  return {
    type: CREATE_CONTACT_FAILURE,
    payload: error,
  };
};

export const patchContactRequest = () => {
  return {
    type: PATCH_CONTACT_REQUEST,
  };
};

export const patchContactSuccess = (patchResult) => {
  return {
    type: PATCH_CONTACT_SUCCESS,
    payload: patchResult,
  };
};

export const patchContactFailure = (error) => {
  return {
    type: PATCH_CONTACT_FAILURE,
    payload: error,
  };
};
