import {
  CREATE_REIMBURSEMENT_DOMESTIC_REQUEST,
  CREATE_REIMBURSEMENT_DOMESTIC_SUCCESS,
  CREATE_REIMBURSEMENT_DOMESTIC_FAILURE,
  PATCH_REIMBURSEMENT_DOMESTIC_REQUEST,
  PATCH_REIMBURSEMENT_DOMESTIC_SUCCESS,
  PATCH_REIMBURSEMENT_DOMESTIC_FAILURE,
  CREATE_REIMBURSEMENT_IMPORT_REQUEST,
  CREATE_REIMBURSEMENT_IMPORT_SUCCESS,
  CREATE_REIMBURSEMENT_IMPORT_FAILURE,
  PATCH_REIMBURSEMENT_IMPORT_REQUEST,
  PATCH_REIMBURSEMENT_IMPORT_SUCCESS,
  PATCH_REIMBURSEMENT_IMPORT_FAILURE,
  POST_DOMESTIC_REIMBURSEMENT_ITEM_SUCCESS,
  POST_DOMESTIC_REIMBURSEMENT_ITEM_FAILURE,
  POST_IMPORT_REIMBURSEMENT_ITEM_SUCCESS,
  POST_IMPORT_REIMBURSEMENT_ITEM_FAILURE,
  FETCH_REIMBURSEMENTS_REQUEST,
  FETCH_REIMBURSEMENTS_SUCCESS,
} from './reimbursementTypes';

import history from '@iso/lib/helpers/history';
import { getAuthClient } from '../../library/drupal/DrupalAuth2';

const auth = getAuthClient();

export const createReimbursementDomestic = (data) => {
  const url = `/jsonapi/node/reimbursement_request`;
  const options = {
    method: 'POST',
    headers: new Headers({
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
    mode: 'cors',
    body: JSON.stringify(data),
  };
  return async (dispatch) => {
    dispatch(createReimbursementDomesticRequest());
    try {
      const response = await auth.fetchWithAuthentication(url, options);
      if (response.ok) {
        dispatch(createReimbursementDomesticSuccess(JSON.stringify(response)));
        const data = await response.json();
        const newFormID = data.data.id;
        history.push(
          `/dashboard/formCertification/reimbursement_request/${newFormID}`
        );
      } else {
        dispatch(createReimbursementDomesticFailure('An error has occured.'));
      }
    } catch (error) {
      const errorMsg = error.message;
      dispatch(createReimbursementDomesticFailure(errorMsg));
    }
  };
};

export const patchReimbursementDomestic = (formUID, data, domesticOrphans) => {
  const url = `/jsonapi/node/reimbursement_request/${formUID}`;
  const options = {
    method: 'PATCH',
    headers: new Headers({
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
    mode: 'cors',
    body: JSON.stringify(data),
  };
  return async (dispatch) => {
    dispatch(patchReimbursementDomesticRequest());
    try {
      const response = await auth.fetchWithAuthentication(url, options);
      if (response.ok) {
        dispatch(patchReimbursementDomesticSuccess(JSON.stringify(response)));
        cleanUpReimbursementDomesticOrphans(domesticOrphans);
        const data = await response.json();
        const formID = data.data.id;
        history.push(
          `/dashboard/formCertification/reimbursement_request/${formID}`
        );
      } else {
        dispatch(patchReimbursementDomesticFailure('An error has occured.'));
      }
    } catch (error) {
      const errorMsg = error.message;
      dispatch(patchReimbursementDomesticFailure(errorMsg));
    }
  };
};

export const cleanUpReimbursementDomesticOrphans = (domesticOrphans) => {
  console.log('DOMESTIC ORPHANS', domesticOrphans);
  if (domesticOrphans.length > 0) {
    console.log('about to loop em');
    domesticOrphans.forEach((domesticOrphan) => {
      console.log('dispatching delete');
      deleteReimbursementDomesticOrphan(domesticOrphan);
    });
  }
};

export const deleteReimbursementDomesticOrphan = async (orphan) => {
  const url = `/jsonapi/node/domestic_reimb_item/${orphan.id}`;
  const options = {
    method: 'DELETE',
    headers: new Headers({
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
    mode: 'cors',
    body: '',
  };

  try {
    const response = await auth.fetchWithAuthentication(url, options);
    if (response.ok) {
    } else {
    }
  } catch (error) {
    console.log('catch error');
  }
};

export const createReimbursementImport = (data) => {
  const url = `/jsonapi/node/reimbursement_request`;
  const options = {
    method: 'POST',
    headers: new Headers({
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
    mode: 'cors',
    body: JSON.stringify(data),
  };
  return async (dispatch) => {
    dispatch(createReimbursementImportRequest());
    try {
      const response = await auth.fetchWithAuthentication(url, options);
      if (response.ok) {
        dispatch(createReimbursementImportSuccess(JSON.stringify(response)));
        const data = await response.json();
        const newFormID = data.data.id;
        history.push(
          `/dashboard/formCertification/reimbursement_request/${newFormID}`
        );
      } else {
        dispatch(createReimbursementImportFailure('An error has occured.'));
      }
    } catch (error) {
      const errorMsg = error.message;
      dispatch(createReimbursementImportFailure(errorMsg));
    }
  };
};

export const patchReimbursementImport = (formUID, data, importOrphans) => {
  const url = `/jsonapi/node/reimbursement_request/${formUID}`;
  const options = {
    method: 'PATCH',
    headers: new Headers({
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
    mode: 'cors',
    body: JSON.stringify(data),
  };
  return async (dispatch) => {
    dispatch(patchReimbursementImportRequest());
    try {
      const response = await auth.fetchWithAuthentication(url, options);
      if (response.ok) {
        dispatch(patchReimbursementImportSuccess(JSON.stringify(response)));
        cleanUpReimbursementImportOrphans(importOrphans);
        const data = await response.json();
        const formID = data.data.id;
        history.push(
          `/dashboard/formCertification/reimbursement_request/${formID}`
        );
      } else {
        dispatch(patchReimbursementImportFailure('An error has occured.'));
      }
    } catch (error) {
      const errorMsg = error.message;
      dispatch(patchReimbursementImportFailure(errorMsg));
    }
  };
};

export const cleanUpReimbursementImportOrphans = (importOrphans) => {
  console.log('IMPORT ORPHANS', importOrphans);
  if (importOrphans.length > 0) {
    console.log('about to loop em');
    importOrphans.forEach((importOrphan) => {
      console.log('dispatching delete');
      deleteReimbursementImportOrphan(importOrphan);
    });
  }
};

export const deleteReimbursementImportOrphan = async (orphan) => {
  const url = `/jsonapi/node/import_reimb_item/${orphan.id}`;
  const options = {
    method: 'DELETE',
    headers: new Headers({
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
    mode: 'cors',
    body: '',
  };

  try {
    const response = await auth.fetchWithAuthentication(url, options);
    if (response.ok) {
    } else {
    }
  } catch (error) {
    console.log('catch error');
  }
};

export const postDomesticReimbursementItem = (data) => {
  const url = `/jsonapi/node/domestic_reimb_item`;
  const options = {
    method: 'POST',
    headers: new Headers({
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
    mode: 'cors',
    body: JSON.stringify(data),
  };
  return async (dispatch) => {
    try {
      const response = await auth.fetchWithAuthentication(url, options);
      if (response.ok) {
        const data = await response.json();
        dispatch(postDomesticReimbursementItemSuccess(data.data));
      } else {
        throw new Error(`message: ${response.status}`);
      }
    } catch (error) {
      const errorMsg = error.message;
      dispatch(postDomesticReimbursementItemFailure(errorMsg));
    }
  };
};

export const postImportReimbursementItem = (data) => {
  const url = `/jsonapi/node/import_reimb_item`;
  const options = {
    method: 'POST',
    headers: new Headers({
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
    mode: 'cors',
    body: JSON.stringify(data),
  };
  return async (dispatch) => {
    try {
      const response = await auth.fetchWithAuthentication(url, options);
      if (response.ok) {
        const data = await response.json();
        dispatch(postImportReimbursementItemSuccess(data.data));
      } else {
        throw new Error(`message: ${response.status}`);
      }
    } catch (error) {
      const errorMsg = error.message;
      dispatch(postImportReimbursementItemFailure(errorMsg));
    }
  };
};

export const fetchReimbursements = (companyUID, seasons) => {
  return async (dispatch) => {
    dispatch(fetchReimbursementsRequest());
    let reimbursements = [];
    for (const season of seasons) {
      const seasonReimbursements = await fetchReimbursementsBySeason(
        companyUID,
        season.id
      );
      if (seasonReimbursements !== null) {
        reimbursements.push(seasonReimbursements.data);
      }
    }
    dispatch(fetchReimbursementsSuccess(reimbursements));
  };
};

export const fetchReimbursementsBySeason = async (companyUID, seasonID) => {
  if (companyUID !== null) {
    const path = '/jsonapi/node/reimbursement_request';
    const filter = `filter[and-group][group][conjunction]=AND&filter[company-filter][condition][path]=field_appb_company.id&filter[company-filter][condition][value]=${companyUID}&filter[company-filter][condition][memberOf]=and-group&filter[season-filter][condition][path]=field_season.id&filter[season-filter][condition][value]=${seasonID}&filter[season-filter][condition][memberOf]=and-group&filter[status-filter][condition][path]=field_submission_status&filter[status-filter][condition][value]=certified&filter[status-filter][condition][memberOf]=and-group`;
    const url = `${path}?${filter}`;
    const options = {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
    };
    try {
      const response = await auth.fetchWithAuthentication(url, options);
      if (response.ok) {
        const data = await response.json();
        const reimbursementsBySeason = data;
        return reimbursementsBySeason;
      } else {
        return null;
      }
    } catch (error) {}
  }
};

export const createReimbursementDomesticRequest = () => {
  return {
    type: CREATE_REIMBURSEMENT_DOMESTIC_REQUEST,
  };
};

export const createReimbursementDomesticSuccess = (success) => {
  return {
    type: CREATE_REIMBURSEMENT_DOMESTIC_SUCCESS,
    payload: success.data,
  };
};

export const createReimbursementDomesticFailure = (error) => {
  return {
    type: CREATE_REIMBURSEMENT_DOMESTIC_FAILURE,
    payload: error,
  };
};

export const patchReimbursementDomesticRequest = () => {
  return {
    type: PATCH_REIMBURSEMENT_DOMESTIC_REQUEST,
  };
};

export const patchReimbursementDomesticSuccess = (success) => {
  return {
    type: PATCH_REIMBURSEMENT_DOMESTIC_SUCCESS,
    payload: success.data,
  };
};

export const patchReimbursementDomesticFailure = (error) => {
  return {
    type: PATCH_REIMBURSEMENT_DOMESTIC_FAILURE,
    payload: error,
  };
};

export const createReimbursementImportRequest = () => {
  return {
    type: CREATE_REIMBURSEMENT_IMPORT_REQUEST,
  };
};

export const createReimbursementImportSuccess = (success) => {
  return {
    type: CREATE_REIMBURSEMENT_IMPORT_SUCCESS,
    payload: success.data,
  };
};

export const createReimbursementImportFailure = (error) => {
  return {
    type: CREATE_REIMBURSEMENT_IMPORT_FAILURE,
    payload: error,
  };
};

export const patchReimbursementImportRequest = () => {
  return {
    type: PATCH_REIMBURSEMENT_IMPORT_REQUEST,
  };
};

export const patchReimbursementImportSuccess = (success) => {
  return {
    type: PATCH_REIMBURSEMENT_IMPORT_SUCCESS,
    payload: success.data,
  };
};

export const patchReimbursementImportFailure = (error) => {
  return {
    type: PATCH_REIMBURSEMENT_IMPORT_FAILURE,
    payload: error,
  };
};

export const postDomesticReimbursementItemSuccess = (postResult) => {
  return {
    type: POST_DOMESTIC_REIMBURSEMENT_ITEM_SUCCESS,
    payload: postResult,
  };
};

export const postDomesticReimbursementItemFailure = (error) => {
  return {
    type: POST_DOMESTIC_REIMBURSEMENT_ITEM_FAILURE,
    payload: error,
  };
};

export const postImportReimbursementItemSuccess = (postResult) => {
  return {
    type: POST_IMPORT_REIMBURSEMENT_ITEM_SUCCESS,
    payload: postResult,
  };
};

export const postImportReimbursementItemFailure = (error) => {
  return {
    type: POST_IMPORT_REIMBURSEMENT_ITEM_FAILURE,
    payload: error,
  };
};

export const fetchReimbursementsRequest = () => {
  return {
    type: FETCH_REIMBURSEMENTS_REQUEST,
  };
};

export const fetchReimbursementsSuccess = (reimbursements) => {
  return {
    type: FETCH_REIMBURSEMENTS_SUCCESS,
    payload: reimbursements,
  };
};
