import {
  FETCH_ALL_EXEMPTIONS_REQUEST,
  FETCH_ALL_EXEMPTIONS_SUCCESS,
  FETCH_ALL_EXEMPTIONS_FAILURE,
  FETCH_EXEMPTIONS_REQUEST,
  FETCH_EXEMPTIONS_SUCCESS,
  CREATE_EXEMPTION_DEMINIMIS_REQUEST,
  CREATE_EXEMPTION_DEMINIMIS_SUCCESS,
  CREATE_EXEMPTION_DEMINIMIS_FAILURE,
  PATCH_EXEMPTION_DEMINIMIS_REQUEST,
  PATCH_EXEMPTION_DEMINIMIS_SUCCESS,
  PATCH_EXEMPTION_DEMINIMIS_FAILURE,
  CREATE_EXEMPTION_ORGANIC_REQUEST,
  CREATE_EXEMPTION_ORGANIC_SUCCESS,
  CREATE_EXEMPTION_ORGANIC_FAILURE,
  PATCH_EXEMPTION_ORGANIC_REQUEST,
  PATCH_EXEMPTION_ORGANIC_SUCCESS,
  PATCH_EXEMPTION_ORGANIC_FAILURE,
} from './exemptionTypes';

const initialState = {
  loading: false,
  allExemptions: [],
  allExemptionsBySeason: [],
  error: '',
};

const exemptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_EXEMPTIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_EXEMPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        allExemptions: action.payload,
        error: '',
      };
    case FETCH_ALL_EXEMPTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        allExemptions: [],
        error: action.payload,
      };
    case FETCH_EXEMPTIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_EXEMPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        allExemptionsBySeason: action.payload,
        error: '',
      };
    case CREATE_EXEMPTION_DEMINIMIS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_EXEMPTION_DEMINIMIS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CREATE_EXEMPTION_DEMINIMIS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case PATCH_EXEMPTION_DEMINIMIS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PATCH_EXEMPTION_DEMINIMIS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case PATCH_EXEMPTION_DEMINIMIS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CREATE_EXEMPTION_ORGANIC_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_EXEMPTION_ORGANIC_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CREATE_EXEMPTION_ORGANIC_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case PATCH_EXEMPTION_ORGANIC_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PATCH_EXEMPTION_ORGANIC_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case PATCH_EXEMPTION_ORGANIC_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default exemptionsReducer;
