import {
  POST_FH_NON_EXEMPT_RECEIPT_SUCCESS,
  POST_FH_NON_EXEMPT_RECEIPT_FAILURE,
  POST_FH_EXEMPT_RECEIPT_SUCCESS,
  POST_FH_EXEMPT_RECEIPT_FAILURE,
  CLEAR_FH_NON_EXEMPT_RECEIPT_ITEMS,
  CLEAR_FH_EXEMPT_RECEIPT_ITEMS,
  PATCH_FORM_REQUEST,
  PATCH_FORM_SUCCESS,
  PATCH_FORM_FAILURE,
  AMEND_FORM_REQUEST,
  AMEND_FORM_SUCCESS,
  AMEND_FORM_FAILURE,
} from './firstHandlerTypes';

const initialState = {
  loading: false,
  nonExemptReferences: [],
  exemptReferences: [],
  error: '',
};

const firstHandlerReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_FH_NON_EXEMPT_RECEIPT_SUCCESS: {
      let nonExemptRefs = state.nonExemptReferences;
      nonExemptRefs.push(action.payload);
      return {
        ...state,
        nonExemptReferences: nonExemptRefs,
      };
    }
    case POST_FH_NON_EXEMPT_RECEIPT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_FH_NON_EXEMPT_RECEIPT_ITEMS:
      return {
        ...state,
        nonExemptReferences: [],
      };
    case POST_FH_EXEMPT_RECEIPT_SUCCESS: {
      let exemptRefs = state.exemptReferences;
      exemptRefs.push(action.payload);
      return {
        ...state,
        exemptReferences: exemptRefs,
      };
    }
    case POST_FH_EXEMPT_RECEIPT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_FH_EXEMPT_RECEIPT_ITEMS:
      return {
        ...state,
        exemptReferences: [],
      };
    case PATCH_FORM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PATCH_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case PATCH_FORM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case AMEND_FORM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case AMEND_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case AMEND_FORM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default firstHandlerReducer;
