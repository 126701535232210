import { store } from './store';
import authActions from '@iso/redux/auth/actions';
import {
  checkAutoLogout,
  checkUserProfile,
  checkProxy,
  checkCompanyProfile,
  checkSeasons,
} from './';

export default () =>
  new Promise(() => {
    store.dispatch(authActions.checkAuthorization());
  });

new Promise(() => {
  store.dispatch(checkAutoLogout());
});

new Promise(() => {
  store.dispatch(checkUserProfile());
});

new Promise(() => {
  store.dispatch(checkProxy());
});

new Promise(() => {
  store.dispatch(checkCompanyProfile());
});

new Promise(() => {
  store.dispatch(checkSeasons());
});
