import {
  FETCH_COMPANY_PROFILE_REQUEST,
  FETCH_COMPANY_PROFILE_SUCCESS,
  FETCH_COMPANY_PROFILE_FAILURE,
  CHECK_COMPANY_PROFILE,
  PATCH_COMPANY_REQUEST,
  PATCH_COMPANY_SUCCESS,
  PATCH_COMPANY_FAILURE,
  FETCH_COMPANIES_REQUEST,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_FAILURE,
} from './companyTypes';
import { getAuthClient } from '../../library/drupal/DrupalAuth2';

const auth = getAuthClient();

export const fetchCompanyProfile = (companyUID) => {
  if (companyUID !== null) {
    const url = `/jsonapi/node/appb_company_contact/${companyUID}`;

    const options = {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
    };
    return async (dispatch) => {
      dispatch(fetchCompanyProfileRequest);
      try {
        const response = await auth.fetchWithAuthentication(url, options);
        if (response.ok) {
          const data = await response.json();
          const companyProfile = data.data;
          //console.log("COMPANY PROFILE DATA", companyProfile);
          const companyName = companyProfile['title'];
          const companyRootAPPBID = companyProfile['field_appb_root_id'];
          const companyStatus = companyProfile['field_company_status'];
          const companyIsFirstHandler =
            companyProfile['field_company_type'].includes('first_handler');
          const companyIsImporter =
            companyProfile['field_company_type'].includes('importer');
          const companyIsProducer =
            companyProfile['field_company_type'].includes('producer');
          localStorage.setItem('company_name', companyName);
          localStorage.setItem('company_root_appb_id', companyRootAPPBID);
          localStorage.setItem('company_status', companyStatus);
          localStorage.setItem(
            'company_is_first_handler',
            companyIsFirstHandler
          );
          localStorage.setItem('company_is_importer', companyIsImporter);
          localStorage.setItem('company_is_producer', companyIsProducer);
          localStorage.setItem(
            'company_profile',
            JSON.stringify(companyProfile)
          );
          await dispatch(
            fetchCompanyProfileSuccess(
              companyName,
              companyRootAPPBID,
              companyStatus,
              companyIsFirstHandler,
              companyIsImporter,
              companyIsProducer,
              companyProfile
            )
          );
        } else {
          throw new Error(`message: ${response.status}`);
        }
      } catch (error) {
        //console.log("COMPANY PROFILE FETCH FAILURE");
        const errorMsg = error.message;
        dispatch(fetchCompanyProfileFailure(errorMsg));
      }
    };
  }
};

export const fetchCompanyProfileRequest = () => {
  return {
    type: FETCH_COMPANY_PROFILE_REQUEST,
  };
};

export const fetchCompanyProfileSuccess = (
  companyName,
  companyRootAPPBID,
  companyStatus,
  companyIsFirstHandler,
  companyIsImporter,
  companyIsProducer,
  companyProfile
) => {
  return {
    type: FETCH_COMPANY_PROFILE_SUCCESS,
    companyName: companyName,
    companyRootAPPBID: companyRootAPPBID,
    companyStatus: companyStatus,
    companyIsFirstHandler: companyIsFirstHandler,
    companyIsImporter: companyIsImporter,
    companyIsProducer: companyIsProducer,
    companyProfile: companyProfile,
  };
};

export const fetchCompanyProfileFailure = (error) => {
  return {
    type: FETCH_COMPANY_PROFILE_FAILURE,
    payload: error,
  };
};

export const checkCompanyProfile = () => {
  return {
    type: CHECK_COMPANY_PROFILE,
    companyName: localStorage.getItem('company_name'),
    companyRootAPPBID: localStorage.getItem('company_root_appb_id'),
    companyStatus: localStorage.getItem('company_status'),
    companyIsFirstHandler: localStorage.getItem('company_is_first_handler'),
    companyIsImporter: localStorage.getItem('company_is_importer'),
    companyIsProducer: localStorage.getItem('company_is_producer'),
    companyProfile: JSON.parse(localStorage.getItem('company_profile')),
    companies: JSON.parse(localStorage.getItem('companies')),
  };
};

export const patchCompanyRequest = () => {
  return {
    type: PATCH_COMPANY_REQUEST,
  };
};

export const patchCompanySuccess = () => {
  return {
    type: PATCH_COMPANY_SUCCESS,
  };
};

export const patchCompanyFailure = () => {
  return {
    type: PATCH_COMPANY_FAILURE,
  };
};

export const fetchCompanies = () => {
  //const url = `/jsonapi/node/appb_company_contact?page[limit]=1000`;
  const path = '/jsonapi/node/appb_company_contact';
  const filter = `filter[or-group][group][conjunction]=OR&filter[fh-filter][condition][path]=field_company_type&filter[fh-filter][condition][value]=first_handler&filter[fh-filter][condition][memberOf]=or-group&filter[importer-filter][condition][path]=field_company_type&filter[importer-filter][condition][value]=importer&filter[importer-filter][condition][memberOf]=or-group&page[limit]=1000`;
  const url = `${path}?${filter}`;

  const options = {
    method: 'GET',
    headers: new Headers({
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
    mode: 'cors',
  };
  return async (dispatch) => {
    dispatch(fetchCompaniesRequest);
    try {
      const response = await auth.fetchWithAuthentication(url, options);
      if (response.ok) {
        const data = await response.json();
        const companies = data.data;
        localStorage.setItem('companies', JSON.stringify(companies));
        await dispatch(fetchCompaniesSuccess(companies));
      } else {
        throw new Error(`message: ${response.status}`);
      }
    } catch (error) {
      console.log('COMPANIES FETCH FAILURE');
      const errorMsg = error.message;
      dispatch(fetchCompaniesFailure(errorMsg));
    }
  };
};

export const fetchCompaniesRequest = () => {
  return {
    type: FETCH_COMPANIES_REQUEST,
  };
};

export const fetchCompaniesSuccess = (companies) => {
  return {
    type: FETCH_COMPANIES_SUCCESS,
    companies: companies,
  };
};

export const fetchCompaniesFailure = (error) => {
  return {
    type: FETCH_COMPANIES_FAILURE,
    payload: error,
  };
};
