import {
  FETCH_COMPANY_PROFILE_REQUEST,
  FETCH_COMPANY_PROFILE_SUCCESS,
  FETCH_COMPANY_PROFILE_FAILURE,
  CHECK_COMPANY_PROFILE,
  FETCH_COMPANIES_REQUEST,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_FAILURE,
} from './companyTypes';

const initialState = {
  loading: false,
  companyName: null,
  companyRootAPPBID: null,
  companyStatus: null,
  companyIsFirstHandler: false,
  companyIsImporter: false,
  companyIsProducer: false,
  companyProfile: [],
  error: '',
  companies: [],
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMPANY_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_COMPANY_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        companyName: action.companyName,
        companyRootAPPBID: action.companyRootAPPBID,
        companyStatus: action.companyStatus,
        companyIsFirstHandler: action.companyIsFirstHandler,
        companyIsImporter: action.companyIsImporter,
        companyIsProducer: action.companyIsProducer,
        companyProfile: action.companyProfile,
        error: '',
      };
    case FETCH_COMPANY_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CHECK_COMPANY_PROFILE:
      return {
        ...state,
        companyName: action.companyName,
        companyRootAPPBID: action.companyRootAPPBID,
        companyStatus: action.companyStatus,
        companyIsFirstHandler: action.companyIsFirstHandler,
        companyIsImporter: action.companyIsImporter,
        companyIsProducer: action.companyIsProducer,
        companyProfile: action.companyProfile,
        companies: action.companies,
      };
    case FETCH_COMPANIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_COMPANIES_SUCCESS:
      return {
        ...state,
        loading: false,
        companies: action.companies,
        error: '',
      };
    case FETCH_COMPANIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default companyReducer;
