import {
  POST_IMPORTER_NON_EXEMPT_RECEIPT_SUCCESS,
  POST_IMPORTER_NON_EXEMPT_RECEIPT_FAILURE,
  POST_IMPORTER_EXEMPT_RECEIPT_SUCCESS,
  POST_IMPORTER_EXEMPT_RECEIPT_FAILURE,
  CLEAR_IMPORTER_NON_EXEMPT_RECEIPT_ITEMS,
  CLEAR_IMPORTER_EXEMPT_RECEIPT_ITEMS,
  PATCH_FORM_REQUEST,
  PATCH_FORM_SUCCESS,
  PATCH_FORM_FAILURE,
  AMEND_FORM_REQUEST,
  AMEND_FORM_SUCCESS,
  AMEND_FORM_FAILURE,
} from './importerTypes';

import {
  // fetchImporterFormsPackages,
  selectCurrentImporterFormsPackage,
  // selectCurrentImporterForm,
  fetchForm,
} from '../../redux';

import history from '@iso/lib/helpers/history';
import { getAuthClient } from '../../library/drupal/DrupalAuth2';

const auth = getAuthClient();

export const postImporterNonExemptReceiptItem = (data) => {
  const url = `/jsonapi/node/importer_non_exempt_receipt`;
  const options = {
    method: 'POST',
    headers: new Headers({
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
    mode: 'cors',
    body: JSON.stringify(data),
  };
  return async (dispatch) => {
    try {
      const response = await auth.fetchWithAuthentication(url, options);
      if (response.ok) {
        const data = await response.json();
        dispatch(postImporterNonExemptReceiptItemSuccess(data.data));
      } else {
        throw new Error(`message: ${response.status}`);
      }
    } catch (error) {
      const errorMsg = error.message;
      dispatch(postImporterNonExemptReceiptItemFailure(errorMsg));
    }
  };
};

export const postImporterExemptReceiptItem = (data) => {
  const url = `/jsonapi/node/importer_exempt_receipt`;
  const options = {
    method: 'POST',
    headers: new Headers({
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
    mode: 'cors',
    body: JSON.stringify(data),
  };
  return async (dispatch) => {
    try {
      const response = await auth.fetchWithAuthentication(url, options);
      if (response.ok) {
        const data = await response.json();
        dispatch(postImporterExemptReceiptItemSuccess(data.data));
      } else {
        throw new Error(`message: ${response.status}`);
      }
    } catch (error) {
      const errorMsg = error.message;
      dispatch(postImporterExemptReceiptItemFailure(errorMsg));
    }
  };
};

export const patchImporterReport = (
  formID,
  data,
  nonExemptOrphans,
  exemptOrphans
) => {
  if (formID !== null) {
    const url = `/jsonapi/node/importer_report/${formID}`;
    const options = {
      method: 'PATCH',
      headers: new Headers({
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
      body: JSON.stringify(data),
    };
    return async (dispatch) => {
      dispatch(patchImporterReportRequest());
      try {
        const response = await auth.fetchWithAuthentication(url, options);
        if (response.ok) {
          dispatch(patchImporterReportSuccess(JSON.stringify(response)));
          cleanUpImporterNonExemptReceiptOrphans(nonExemptOrphans);
          cleanUpImporterExemptReceiptOrphans(exemptOrphans);
          // let companyUID = null;
          // try {
          //   companyUID = localStorage.getItem('company_uid');
          // } catch {
          //   //throw new Error()
          // }
          // let seasons = null;
          // try {
          //   seasons = JSON.parse(localStorage.getItem('seasons'));
          // } catch {
          //   //throw new error
          // }
          // await dispatch(fetchImporterFormsPackages(companyUID, seasons));
          history.push(
            `/dashboard/formCertification/importer_report/${formID}`
          );
        } else {
          dispatch(patchImporterReportFailure('An error has occured.'));
        }
      } catch (error) {
        const errorMsg = error.message;
        dispatch(patchImporterReportFailure(errorMsg));
      }
    };
  }
};

export const patchDraftImporterReport = (
  formID,
  data,
  nonExemptOrphans,
  exemptOrphans
) => {
  if (formID !== null) {
    const url = `/jsonapi/node/importer_report/${formID}`;
    const options = {
      method: 'PATCH',
      headers: new Headers({
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
      body: JSON.stringify(data),
    };
    return async (dispatch) => {
      dispatch(patchImporterReportRequest());
      try {
        const response = await auth.fetchWithAuthentication(url, options);
        if (response.ok) {
          dispatch(patchImporterReportSuccess(JSON.stringify(response)));
          cleanUpImporterNonExemptReceiptOrphans(nonExemptOrphans);
          cleanUpImporterExemptReceiptOrphans(exemptOrphans);
          //const data = await response.json();
          // let companyUID = null;
          // try {
          //   companyUID = localStorage.getItem('company_uid');
          // } catch {
          //   //throw new Error()
          // }
          // let seasons = null;
          // try {
          //   seasons = JSON.parse(localStorage.getItem('seasons'));
          // } catch {
          //   //throw new error
          // }
          // await dispatch(fetchImporterFormsPackages(companyUID, seasons));
          await dispatch(selectCurrentImporterFormsPackage(formID));
          // await dispatch(selectCurrentImporterForm(formID));
          await dispatch(fetchForm(formID, 'importer_report'));
          return 'draftSuccess';
          // Since it's a draft, don't redirect
          // history.push(
          //   `/dashboard/formCertification/importer_report/${formID}`
          // );
        } else {
          dispatch(patchImporterReportFailure('An error has occured.'));
          return 'draftFailure';
        }
      } catch (error) {
        const errorMsg = error.message;
        dispatch(patchImporterReportFailure(errorMsg));
      }
    };
  }
};

export const amendImporterReport = (formID, currentFormsPackageID, data) => {
  if (formID !== null) {
    const url = `/jsonapi/node/importer_report`;
    const options = {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
      body: JSON.stringify(data),
    };
    return async (dispatch) => {
      dispatch(amendFormRequest());
      try {
        const response = await auth.fetchWithAuthentication(url, options);
        if (response.ok) {
          dispatch(amendFormSuccess(JSON.stringify(response)));
          const data = await response.json();
          const newFormID = data.data.id;
          await dispatch(
            addImporterReportToPackage(newFormID, currentFormsPackageID)
          );
          // let companyUID = null;
          // try {
          //   companyUID = localStorage.getItem('company_uid');
          // } catch {
          //   //throw new Error()
          // }
          // let seasons = null;
          // try {
          //   seasons = JSON.parse(localStorage.getItem('seasons'));
          // } catch {
          //   //throw new error
          // }
          // await dispatch(fetchImporterFormsPackages(companyUID, seasons));
          history.push(
            `/dashboard/formCertification/importer_report/${newFormID}`
          );
        } else {
          dispatch(amendFormFailure('An error has occured.'));
        }
      } catch (error) {
        const errorMsg = error.message;
        dispatch(amendFormFailure(errorMsg));
      }
    };
  }
};

export const addImporterReportToPackage = (formID, packageID) => {
  if (formID !== null && packageID !== null) {
    let data = {};
    data = {
      data: [
        {
          type: 'node--importer_report',
          id: formID,
        },
      ],
    };
    const url = `/jsonapi/node/importer_reporting_forms_package/${packageID}/relationships/field_importer_report`; //POST to a relationship resource will ADD the new node to the multi-value field.  Need to send as array.  PATCH will REPLACE existing field data.
    const options = {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
      body: JSON.stringify(data),
    };
    return async (dispatch) => {
      try {
        const response = await auth.fetchWithAuthentication(url, options);
        if (response.ok) {
          //console.log('ADD TO PACKAGE OK');
        } else {
          dispatch(amendFormFailure('An error has occured.'));
        }
      } catch (error) {
        const errorMsg = error.message;
        dispatch(amendFormFailure(errorMsg));
      }
    };
  }
};

export const cleanUpImporterNonExemptReceiptOrphans = (nonExemptOrphans) => {
  //console.log('NON EX ORPHANS', nonExemptOrphans);
  if (nonExemptOrphans.length > 0) {
    nonExemptOrphans.forEach((nonExemptOrphan) => {
      deleteNonExemptReceiptOrphan(nonExemptOrphan);
    });
  }
};

export const deleteNonExemptReceiptOrphan = async (orphan) => {
  const url = `/jsonapi/node/importer_non_exempt_receipt/${orphan.id}`;
  const options = {
    method: 'DELETE',
    headers: new Headers({
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
    mode: 'cors',
    body: '',
  };

  try {
    const response = await auth.fetchWithAuthentication(url, options);
    if (response.ok) {
    } else {
    }
  } catch (error) {
    console.log('catch error');
  }
};

export const cleanUpImporterExemptReceiptOrphans = (exemptOrphans) => {
  //console.log('EX ORPHANS', exemptOrphans);
  if (exemptOrphans.length > 0) {
    exemptOrphans.forEach((exemptOrphan) => {
      deleteExemptReceiptOrphan(exemptOrphan);
    });
  }
};

export const deleteExemptReceiptOrphan = async (orphan) => {
  const url = `/jsonapi/node/importer_exempt_receipt/${orphan.id}`;
  const options = {
    method: 'DELETE',
    headers: new Headers({
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
    mode: 'cors',
    body: '',
  };

  try {
    const response = await auth.fetchWithAuthentication(url, options);
    if (response.ok) {
    } else {
    }
  } catch (error) {
    console.log('catch error');
  }
};

export const postImporterNonExemptReceiptItemSuccess = (postResult) => {
  return {
    type: POST_IMPORTER_NON_EXEMPT_RECEIPT_SUCCESS,
    payload: postResult,
  };
};

export const postImporterNonExemptReceiptItemFailure = (error) => {
  return {
    type: POST_IMPORTER_NON_EXEMPT_RECEIPT_FAILURE,
    payload: error,
  };
};

export const clearImporterNonExemptReceiptItems = () => {
  return {
    type: CLEAR_IMPORTER_NON_EXEMPT_RECEIPT_ITEMS,
  };
};

export const postImporterExemptReceiptItemSuccess = (postResult) => {
  return {
    type: POST_IMPORTER_EXEMPT_RECEIPT_SUCCESS,
    payload: postResult,
  };
};

export const postImporterExemptReceiptItemFailure = (error) => {
  return {
    type: POST_IMPORTER_EXEMPT_RECEIPT_FAILURE,
    payload: error,
  };
};

export const clearImporterExemptReceiptItems = () => {
  return {
    type: CLEAR_IMPORTER_EXEMPT_RECEIPT_ITEMS,
  };
};

export const patchImporterReportRequest = () => {
  return {
    type: PATCH_FORM_REQUEST,
  };
};

export const patchImporterReportSuccess = (success) => {
  return {
    type: PATCH_FORM_SUCCESS,
    payload: success.data,
  };
};

export const patchImporterReportFailure = (error) => {
  return {
    type: PATCH_FORM_FAILURE,
    payload: error,
  };
};

export const amendFormRequest = () => {
  return {
    type: AMEND_FORM_REQUEST,
  };
};

export const amendFormSuccess = (patchResult) => {
  return {
    type: AMEND_FORM_SUCCESS,
    payload: patchResult,
  };
};

export const amendFormFailure = (error) => {
  return {
    type: AMEND_FORM_FAILURE,
    payload: error,
  };
};
