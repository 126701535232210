export const FETCH_CONTACTS_REQUEST = 'FETCH_CONTACTS_REQUEST';
export const FETCH_CONTACTS_SUCCESS = 'FETCH_CONTACTS_SUCCESS';
export const FETCH_CONTACTS_FAILURE = 'FETCH_CONTACTS_FAILURE';
export const CHANGE_CONTACT = 'CHANGE_CONTACT';
export const CREATE_CONTACT_REQUEST = 'CREATE_CONTACT_REQUEST';
export const CREATE_CONTACT_SUCCESS = 'CREATE_CONTACT_SUCCESS';
export const CREATE_CONTACT_FAILURE = 'CREATE_CONTACT_FAILURE';
export const PATCH_CONTACT_REQUEST = 'PATCH_CONTACT_REQUEST';
export const PATCH_CONTACT_SUCCESS = 'PATCH_CONTACT_SUCCESS';
export const PATCH_CONTACT_FAILURE = 'PATCH_CONTACT_FAILURE';
