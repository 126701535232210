import { FETCH_INVOICE_REQUEST, FETCH_INVOICE_SUCCESS } from './invoiceTypes';

import { getAuthClient } from '../../library/drupal/DrupalAuth2';

const auth = getAuthClient();

export const fetchInvoices = (companyUID, seasons) => {
  return async (dispatch) => {
    dispatch(fetchInvoicesRequest());
    let invoices = [];
    for (const season of seasons) {
      const seasonInvoices = await fetchInvoicesBySeason(companyUID, season.id);
      if (seasonInvoices !== null) {
        invoices.push(seasonInvoices.data);
      }
    }
    dispatch(fetchInvoicesSuccess(invoices));
  };
};

export const fetchInvoicesBySeason = async (companyUID, seasonID) => {
  if (companyUID !== null) {
    const path = '/jsonapi/node/invoice';
    const filter = `filter[and-group][group][conjunction]=AND&filter[company-filter][condition][path]=field_appb_company.id&filter[company-filter][condition][value]=${companyUID}&filter[company-filter][condition][memberOf]=and-group&filter[season-filter][condition][path]=field_reporting_period.field_season.id&filter[season-filter][condition][value]=${seasonID}&filter[season-filter][condition][memberOf]=and-group`;
    const sort =
      'sort[package-sort][path]=field_reporting_period.field_due_date&sort[package-sort][direction]=DESC';
    const include = 'include=field_reporting_period,field_qb_invoice_pdf';
    const url = `${path}?${filter}&${sort}&${include}`;
    const options = {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
    };
    try {
      const response = await auth.fetchWithAuthentication(url, options);
      if (response.ok) {
        const data = await response.json();
        const invoicesBySeason = data;
        return invoicesBySeason;
      } else {
        return null;
      }
    } catch (error) {}
  }
};

export const fetchInvoicesRequest = () => {
  return {
    type: FETCH_INVOICE_REQUEST,
  };
};

export const fetchInvoicesSuccess = (invoices) => {
  return {
    type: FETCH_INVOICE_SUCCESS,
    payload: invoices,
  };
};
