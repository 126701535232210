import {
  FETCH_FH_FORMS_PACKAGES_REQUEST,
  FETCH_FH_FORMS_PACKAGES_SUCCESS,
  // FETCH_FH_FORMS_PACKAGES_FAILURE,
  FETCH_IMPORTER_FORMS_PACKAGES_REQUEST,
  FETCH_IMPORTER_FORMS_PACKAGES_SUCCESS,
  // FETCH_IMPORTER_FORMS_PACKAGES_FAILURE,
  REFRESH_IMPORTER_FORMS_PACKAGES,
  REFRESH_FH_FORMS_PACKAGES,
  CLEAR_CURRENT_FH_FORMS_PACKAGE,
  CLEAR_CURRENT_FH_FORM,
  CLEAR_CURRENT_IMPORTER_FORMS_PACKAGE,
  CLEAR_CURRENT_IMPORTER_FORM,
  SELECT_CURRENT_IMPORTER_FORMS_PACKAGE,
  SELECT_CURRENT_FH_FORMS_PACKAGE,
  SELECT_CURRENT_IMPORTER_FORM,
  SELECT_CURRENT_FH_FORM,
} from './formsPackageTypes';

import { getAuthClient } from '../../library/drupal/DrupalAuth2';

const auth = getAuthClient();

export const fetchFirstHandlerFormsPackages = (companyUID, seasons) => {
  return async (dispatch) => {
    dispatch(fetchFirstHandlerFormsPackagesRequest());
    let fhFormsPackages = [];
    for (const season of seasons) {
      const seasonFHFormsPackages =
        await fetchFirstHandlerFormsPackagesBySeason(companyUID, season.id);
      if (seasonFHFormsPackages !== null) {
        fhFormsPackages.push(seasonFHFormsPackages.data);
      }
    }
    localStorage.setItem('fh_forms_packages', JSON.stringify(fhFormsPackages));
    dispatch(fetchFirstHandlerFormsPackagesSuccess(fhFormsPackages));
  };
};

export const fetchImporterFormsPackages = (companyUID, seasons) => {
  return async (dispatch) => {
    dispatch(fetchImporterFormsPackagesRequest());
    let importerFormsPackages = [];
    for (const season of seasons) {
      const seasonImporterFormsPackages =
        await fetchImporterFormsPackagesBySeason(companyUID, season.id);
      if (seasonImporterFormsPackages !== null) {
        importerFormsPackages.push(seasonImporterFormsPackages.data);
      }
    }
    localStorage.setItem(
      'importer_forms_packages',
      JSON.stringify(importerFormsPackages)
    );
    dispatch(fetchImporterFormsPackagesSuccess(importerFormsPackages));
  };
};

export const fetchFirstHandlerFormsPackagesBySeason = async (
  companyUID,
  seasonID
) => {
  if (companyUID !== null) {
    const path = '/jsonapi/node/fh_reporting_forms_package';
    const filter = `filter[and-group][group][conjunction]=AND&filter[company-filter][condition][path]=field_appb_company.id&filter[company-filter][condition][value]=${companyUID}&filter[company-filter][condition][memberOf]=and-group&filter[season-filter][condition][path]=field_reporting_period.field_season.id&filter[season-filter][condition][value]=${seasonID}&filter[season-filter][condition][memberOf]=and-group`;
    const sort =
      'sort[package-sort][path]=field_reporting_period.field_due_date&sort[package-sort][direction]=DESC';
    //const include = 'include=field_reporting_period,field_fh_report.field_fh_report_pdf';
    // const include =
    //   'include=field_reporting_period,field_fh_report.field_fh_report_pdf,field_fh_report.field_non_exempt_receipts,field_fh_report.field_exempt_receipts';
    const include = 'include=field_reporting_period,field_fh_report';
    const url = `${path}?${filter}&${sort}&${include}`;
    const options = {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
    };
    try {
      const response = await auth.fetchWithAuthentication(url, options);
      if (response.ok) {
        const data = await response.json();
        const formsPackagesBySeason = data;
        return formsPackagesBySeason;
      } else {
        return null;
      }
    } catch (error) {}
  }
};

export const fetchImporterFormsPackagesBySeason = async (
  companyUID,
  seasonID
) => {
  if (companyUID !== null) {
    const path = '/jsonapi/node/importer_reporting_forms_package';
    const filter = `filter[and-group][group][conjunction]=AND&filter[company-filter][condition][path]=field_appb_company.id&filter[company-filter][condition][value]=${companyUID}&filter[company-filter][condition][memberOf]=and-group&filter[season-filter][condition][path]=field_reporting_period.field_season.id&filter[season-filter][condition][value]=${seasonID}&filter[season-filter][condition][memberOf]=and-group`;
    const sort =
      'sort[package-sort][path]=field_reporting_period.field_due_date&sort[package-sort][direction]=DESC';
    //const include = 'include=field_reporting_period,field_importer_report.field_importer_report_pdf';
    // const include =
    //   'include=field_importer_report.field_importer_report_pdf,field_reporting_period,field_importer_report.field_non_exempt_receipts,field_importer_report.field_exempt_receipts';
    const include = 'include=field_reporting_period,field_importer_report';
    const url = `${path}?${filter}&${sort}&${include}`;
    const options = {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
    };
    try {
      const response = await auth.fetchWithAuthentication(url, options);
      if (response.ok) {
        const data = await response.json();
        const formsPackagesBySeason = data;
        return formsPackagesBySeason;
      } else {
        return null;
      }
    } catch (error) {}
  }
};

export const selectCurrentImporterFormsPackage = (formID) => {
  return {
    type: SELECT_CURRENT_IMPORTER_FORMS_PACKAGE,
    formID: formID,
  };
};

export const selectCurrentImporterForm = (formID) => {
  return {
    type: SELECT_CURRENT_IMPORTER_FORM,
    formID: formID,
  };
};

export const refreshImporterFormsPackages = () => {
  return {
    type: REFRESH_IMPORTER_FORMS_PACKAGES,
  };
};

export const clearCurrentImporterFormsPackage = () => {
  return {
    type: CLEAR_CURRENT_IMPORTER_FORMS_PACKAGE,
  };
};

export const clearCurrentImporterForm = () => {
  return {
    type: CLEAR_CURRENT_IMPORTER_FORM,
  };
};

export const selectCurrentFHFormsPackage = (formID) => {
  return {
    type: SELECT_CURRENT_FH_FORMS_PACKAGE,
    formID: formID,
  };
};

export const selectCurrentFHForm = (formID) => {
  return {
    type: SELECT_CURRENT_FH_FORM,
    formID: formID,
  };
};

export const refreshFHFormsPackages = () => {
  return {
    type: REFRESH_FH_FORMS_PACKAGES,
  };
};

export const clearCurrentFHFormsPackage = () => {
  return {
    type: CLEAR_CURRENT_FH_FORMS_PACKAGE,
  };
};

export const clearCurrentFHForm = () => {
  return {
    type: CLEAR_CURRENT_FH_FORM,
  };
};

export const fetchFirstHandlerFormsPackagesRequest = () => {
  return {
    type: FETCH_FH_FORMS_PACKAGES_REQUEST,
  };
};

export const fetchFirstHandlerFormsPackagesSuccess = (fhFormsPackages) => {
  return {
    type: FETCH_FH_FORMS_PACKAGES_SUCCESS,
    payload: fhFormsPackages,
  };
};

// export const fetchFirstHandlerFormsPackagesFailure = (error) => {
//   return {
//     type: FETCH_FH_FORMS_PACKAGES_FAILURE,
//     payload: error,
//   };
// };

export const fetchImporterFormsPackagesRequest = () => {
  return {
    type: FETCH_IMPORTER_FORMS_PACKAGES_REQUEST,
  };
};

export const fetchImporterFormsPackagesSuccess = (importerFormsPackages) => {
  return {
    type: FETCH_IMPORTER_FORMS_PACKAGES_SUCCESS,
    payload: importerFormsPackages,
  };
};

// export const fetchImporterFormsPackagesFailure = (error) => {
//   return {
//     type: FETCH_IMPORTER_FORMS_PACKAGES_FAILURE,
//     payload: error,
//   };
// };
