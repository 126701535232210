import {
  CREATE_REIMBURSEMENT_DOMESTIC_REQUEST,
  CREATE_REIMBURSEMENT_DOMESTIC_SUCCESS,
  CREATE_REIMBURSEMENT_DOMESTIC_FAILURE,
  PATCH_REIMBURSEMENT_DOMESTIC_REQUEST,
  PATCH_REIMBURSEMENT_DOMESTIC_SUCCESS,
  PATCH_REIMBURSEMENT_DOMESTIC_FAILURE,
  CREATE_REIMBURSEMENT_IMPORT_REQUEST,
  CREATE_REIMBURSEMENT_IMPORT_SUCCESS,
  CREATE_REIMBURSEMENT_IMPORT_FAILURE,
  PATCH_REIMBURSEMENT_IMPORT_REQUEST,
  PATCH_REIMBURSEMENT_IMPORT_SUCCESS,
  PATCH_REIMBURSEMENT_IMPORT_FAILURE,
  POST_DOMESTIC_REIMBURSEMENT_ITEM_SUCCESS,
  POST_DOMESTIC_REIMBURSEMENT_ITEM_FAILURE,
  POST_IMPORT_REIMBURSEMENT_ITEM_SUCCESS,
  POST_IMPORT_REIMBURSEMENT_ITEM_FAILURE,
  FETCH_REIMBURSEMENTS_REQUEST,
  FETCH_REIMBURSEMENTS_SUCCESS,
} from './reimbursementTypes';

const initialState = {
  loading: false,
  allReimbursements: [],
  reimbursementReferences: [],
  error: '',
};

const reimbursementReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_REIMBURSEMENT_DOMESTIC_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_REIMBURSEMENT_DOMESTIC_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CREATE_REIMBURSEMENT_DOMESTIC_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case PATCH_REIMBURSEMENT_DOMESTIC_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PATCH_REIMBURSEMENT_DOMESTIC_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case PATCH_REIMBURSEMENT_DOMESTIC_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CREATE_REIMBURSEMENT_IMPORT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_REIMBURSEMENT_IMPORT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CREATE_REIMBURSEMENT_IMPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case PATCH_REIMBURSEMENT_IMPORT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PATCH_REIMBURSEMENT_IMPORT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case PATCH_REIMBURSEMENT_IMPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case POST_DOMESTIC_REIMBURSEMENT_ITEM_SUCCESS: {
      let reimbursementRefs = state.reimbursementReferences;
      reimbursementRefs.push(action.payload);
      return {
        ...state,
        reimbursementReferences: reimbursementRefs,
      };
    }
    case POST_DOMESTIC_REIMBURSEMENT_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case POST_IMPORT_REIMBURSEMENT_ITEM_SUCCESS: {
      let reimbursementRefs = state.reimbursementReferences;
      reimbursementRefs.push(action.payload);
      return {
        ...state,
        reimbursementReferences: reimbursementRefs,
      };
    }
    case POST_IMPORT_REIMBURSEMENT_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_REIMBURSEMENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_REIMBURSEMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        allReimbursements: action.payload,
      };
    default:
      return state;
  }
};

export default reimbursementReducer;
