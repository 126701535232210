import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE } from './loginTypes';
import history from '@iso/lib/helpers/history';
import appAction from '@iso/redux/app/actions';
import {
  fetchUserProfile,
  fetchCompanyProfile,
  fetchSeasons,
  fetchProxy,
  // fetchImporterFormsPackages,
  // fetchFirstHandlerFormsPackages,
} from '../../redux';
import { getAuthClient } from '../../library/drupal/DrupalAuth2';
import { getToken } from '@iso/lib/helpers/utility';

const auth = getAuthClient();
const { clearMenu } = appAction;

export const login = (username = null, password = null) => {
  return async (dispatch) => {
    dispatch(loginRequest);
    try {
      const response = await auth.login(username, password);
      //console.log('Response: ', response);
      if (response) {
        //dispatch(loginSuccess(JSON.stringify(response), username));
        dispatch(loginSuccess(JSON.stringify(response)));
        localStorage.setItem('id_token', JSON.stringify(response)); //necessary for app login status
        await dispatch(fetchUserProfile(username));
        await dispatch(fetchProxy());
        let companyUID = null;
        try {
          companyUID = localStorage.getItem('company_uid');
        } catch {
          //throw new Error()
        }
        await dispatch(fetchCompanyProfile(companyUID));
        await dispatch(fetchSeasons());
        // let seasons = null;
        // try {
        //   seasons = JSON.parse(localStorage.getItem('seasons'));
        // } catch {
        //   //throw new error
        // }
        // if (seasons !== null) {
        //   await dispatch(fetchImporterFormsPackages(companyUID, seasons));
        //   await dispatch(fetchFirstHandlerFormsPackages(companyUID, seasons));
        // }
        await dispatch(clearMenu());
        history.replace('/dashboard');
      } else {
        dispatch(loginFailure('Invalid credentials.  Please try again.'));
      }
    } catch (error) {
      const errorMsg = error.message;
      dispatch(loginFailure(errorMsg));
    }
  };
};

export const logout = () => {
  return (dispatch) => {
    auth.logout();
    localStorage.removeItem('id_token'); //necessary for app login status
    localStorage.removeItem('username');
    localStorage.removeItem('user_uid');
    localStorage.removeItem('user_firstname');
    localStorage.removeItem('company_uid');
    localStorage.removeItem('company_name');
    localStorage.removeItem('company_root_appb_id');
    localStorage.removeItem('company_status');
    localStorage.removeItem('company_profile');
    localStorage.removeItem('seasons');
    localStorage.removeItem('company_is_first_handler');
    localStorage.removeItem('company_is_importer');
    localStorage.removeItem('company_is_producer');
    localStorage.removeItem('fh_forms_packages');
    localStorage.removeItem('importer_forms_packages');
    localStorage.removeItem('companies');
    localStorage.removeItem('proxy');

    dispatch(clearMenu());
    history.replace('/');
  };
};

export const checkAutoLogout = () => {
  return (dispatch) => {
    //console.log("Checking auto logout");
    const token = getToken().get('idToken');
    if (token) {
      let autoLogoutDate = null;
      try {
        //console.log("Looking for autologout datetime");
        autoLogoutDate = localStorage.getItem('auto_logout_datetime');
      } catch {
        //throw new Error()
        //console.log("Autologout datetime error");
      }
      if (autoLogoutDate && autoLogoutDate > Date.now() / 1000) {
        //stay logged in
        //console.log("Autologout date found: ", autoLogoutDate);
      } else {
        //console.log("LOGGING OUT!");
        dispatch(logout());
      }
    } else {
      //console.log("No token found.  Autologout not happening");
    }
  };
};

export const loginRequest = () => {
  return {
    type: LOGIN_REQUEST,
  };
};

export const loginSuccess = (loginData) => {
  return {
    type: LOGIN_SUCCESS,
    payload: loginData,
  };
};

export const loginFailure = (error) => {
  return {
    type: LOGIN_FAILURE,
    payload: error,
  };
};
