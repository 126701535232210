import {
  FETCH_FORM_REQUEST,
  FETCH_FORM_SUCCESS,
  FETCH_FORM_FAILURE,
  CERTIFY_FORM_REQUEST,
  CERTIFY_FORM_SUCCESS,
  CERTIFY_FORM_FAILURE,
  SET_CERTIFIED_FORM_TYPE,
  REFRESH_CERTIFIED_FORM_TYPE,
  RESET_CERTIFIED_FORM_TYPE,
} from './formTypes';
import history from '@iso/lib/helpers/history';
import { getAuthClient } from '../../library/drupal/DrupalAuth2';

const auth = getAuthClient();

export const fetchForm = (formUID, formType) => {
  if (formUID !== null && formType !== null) {
    //switch file field names (too bad we didn't use a single field for all form PDFs in Drupal.  Perhaps this should be changed?)
    let includes = '';
    switch (formType) {
      case 'ex_request_de_minimis':
        includes = 'field_ex_request_dm_pdf';
        break;
      case 'ex_request_organic':
        includes = 'field_ex_request_org_pdf,field_organic_documentaion_pdf';
        break;
      case 'reimbursement_request':
        includes =
          'field_reimb_request_pdf,field_season,field_domestic_reimb_items,field_import_reimb_items';
        break;
      case 'importer_report':
        includes =
          'field_importer_report_pdf,field_reporting_period,field_non_exempt_receipts,field_exempt_receipts';
        break;
      case 'first_handler_report':
        includes =
          'field_fh_report_pdf,field_reporting_period,field_non_exempt_receipts,field_exempt_receipts';
        break;
      default:
        includes = '';
    }

    //fetch a single form
    const path = `/jsonapi/node/${formType}/${formUID}`;
    //const include = `include=${pdf_field},field_reporting_period`;
    const include = `include=${includes}`;
    const url = `${path}?${include}`;
    const options = {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
    };
    return (dispatch) => {
      dispatch(fetchFormRequest());
      auth
        .fetchWithAuthentication(url, options)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          const form = data;
          dispatch(fetchFormSuccess(form));
        })
        .catch((error) => {
          const errorMsg = error.message;
          dispatch(fetchFormFailure(errorMsg));
        });
    };
  }
};

export const certifyForm = (formUID, formType, data, isAmend) => {
  if (formUID !== null && formType !== null) {
    let formNotificationType = formType;
    if (formType === 'first_handler_report' && isAmend) {
      formNotificationType = 'first_handler_report_amend';
    }
    if (formType === 'importer_report' && isAmend) {
      formNotificationType = 'importer_report_amend';
    }
    const url = `/jsonapi/node/${formType}/${formUID}`;
    const options = {
      method: 'PATCH',
      headers: new Headers({
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
      body: JSON.stringify(data),
    };
    return async (dispatch) => {
      dispatch(certifyFormRequest());
      try {
        const response = await auth.fetchWithAuthentication(url, options);
        if (response.ok) {
          dispatch(certifyFormSuccess(JSON.stringify(response)));
          localStorage.setItem(
            'new_form_certification_type',
            formNotificationType
          );
          await dispatch(setNewFormCertificationType(formNotificationType));
          history.push(`/dashboard`);
        } else {
          dispatch(certifyFormFailure('An error has occured.'));
        }
      } catch (error) {
        const errorMsg = error.message;
        dispatch(certifyFormFailure(errorMsg));
      }
    };
  }
};

export const fetchFormRequest = () => {
  return {
    type: FETCH_FORM_REQUEST,
  };
};

export const fetchFormSuccess = (form) => {
  return {
    type: FETCH_FORM_SUCCESS,
    payload: form.data,
  };
};

export const fetchFormFailure = (error) => {
  return {
    type: FETCH_FORM_FAILURE,
    payload: error,
  };
};

export const certifyFormRequest = () => {
  return {
    type: CERTIFY_FORM_REQUEST,
  };
};

export const certifyFormSuccess = (certifyResult) => {
  return {
    type: CERTIFY_FORM_SUCCESS,
    payload: certifyResult,
  };
};

export const certifyFormFailure = (error) => {
  return {
    type: CERTIFY_FORM_FAILURE,
    payload: error,
  };
};

export const setNewFormCertificationType = (formType) => {
  return {
    type: SET_CERTIFIED_FORM_TYPE,
    payload: formType,
  };
};

export const refreshNewCertificationType = () => {
  return {
    type: REFRESH_CERTIFIED_FORM_TYPE,
  };
};

export const resetNewCertificationType = () => {
  return {
    type: RESET_CERTIFIED_FORM_TYPE,
  };
};
