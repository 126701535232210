import { combineReducers } from 'redux';
import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/auth/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import Ecommerce from '@iso/redux/ecommerce/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import loginReducer from './login/loginReducer';
import userReducer from './user/userReducer';
import companyReducer from './company/companyReducer';
import seasonReducer from './season/seasonReducer';
import companyContactsReducer from './companyContacts/companyContactsReducer';
import exemptionReducer from './exemption/exemptionReducer';
import reimbursementReducer from './reimbursement/reimbursementReducer';
import formReducer from './form/formReducer';
import formsPackageReducer from './formsPackage/formsPackageReducer';
import importerReducer from './importer/importerReducer';
import firstHandlerReducer from './firstHandler/firstHandlerReducer';
import invoiceReducer from './invoice/invoiceReducer';

export default combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  Ecommerce,
  LanguageSwitcher,
  loginReducer,
  userReducer,
  companyReducer,
  seasonReducer,
  companyContactsReducer,
  exemptionReducer,
  formReducer,
  reimbursementReducer,
  formsPackageReducer,
  importerReducer,
  firstHandlerReducer,
  invoiceReducer,
});
