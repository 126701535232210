export const FETCH_ALL_EXEMPTIONS_REQUEST = 'FETCH_ALL_EXEMPTIONS_REQUEST';
export const FETCH_ALL_EXEMPTIONS_SUCCESS = 'FETCH_ALL_EXEMPTIONS_SUCCESS';
export const FETCH_ALL_EXEMPTIONS_FAILURE = 'FETCH_ALL_EXEMPTIONS_FAILURE';
export const FETCH_EXEMPTIONS_REQUEST = 'FETCH_EXEMPTIONS_REQUEST';
export const FETCH_EXEMPTIONS_SUCCESS = 'FETCH_EXEMPTIONS_SUCCESS';
export const CREATE_EXEMPTION_DEMINIMIS_REQUEST =
  'CREATE_EXEMPTION_DEMINIMIS_REQUEST';
export const CREATE_EXEMPTION_DEMINIMIS_SUCCESS =
  'CREATE_EXEMPTION_DEMINIMIS_SUCCESS';
export const CREATE_EXEMPTION_DEMINIMIS_FAILURE =
  'CREATE_EXEMPTION_DEMINIMIS_FAILURE';
export const PATCH_EXEMPTION_DEMINIMIS_REQUEST =
  'PATCH_EXEMPTION_DEMINIMIS_REQUEST';
export const PATCH_EXEMPTION_DEMINIMIS_SUCCESS =
  'PATCH_EXEMPTION_DEMINIMIS_SUCCESS';
export const PATCH_EXEMPTION_DEMINIMIS_FAILURE =
  'PATCH_EXEMPTION_DEMINIMIS_FAILURE';
export const CREATE_EXEMPTION_ORGANIC_REQUEST =
  'CREATE_EXEMPTION_ORGANIC_REQUEST';
export const CREATE_EXEMPTION_ORGANIC_SUCCESS =
  'CREATE_EXEMPTION_ORGANIC_SUCCESS';
export const CREATE_EXEMPTION_ORGANIC_FAILURE =
  'CREATE_EXEMPTION_ORGANIC_FAILURE';
export const PATCH_EXEMPTION_ORGANIC_REQUEST =
  'PATCH_EXEMPTION_ORGANIC_REQUEST';
export const PATCH_EXEMPTION_ORGANIC_SUCCESS =
  'PATCH_EXEMPTION_ORGANIC_SUCCESS';
export const PATCH_EXEMPTION_ORGANIC_FAILURE =
  'PATCH_EXEMPTION_ORGANIC_FAILURE';
