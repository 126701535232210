export const CREATE_REIMBURSEMENT_DOMESTIC_REQUEST =
  'CREATE_REIMBURSEMENT_DOMESTIC_REQUEST';
export const CREATE_REIMBURSEMENT_DOMESTIC_SUCCESS =
  'CREATE_REIMBURSEMENT_DOMESTIC_SUCCESS';
export const CREATE_REIMBURSEMENT_DOMESTIC_FAILURE =
  'CREATE_REIMBURSEMENT_DOMESTIC_FAILURE';
export const PATCH_REIMBURSEMENT_DOMESTIC_REQUEST =
  'PATCH_REIMBURSEMENT_DOMESTIC_REQUEST';
export const PATCH_REIMBURSEMENT_DOMESTIC_SUCCESS =
  'PATCH_REIMBURSEMENT_DOMESTIC_SUCCESS';
export const PATCH_REIMBURSEMENT_DOMESTIC_FAILURE =
  'PATCH_REIMBURSEMENT_DOMESTIC_FAILURE';
export const CREATE_REIMBURSEMENT_IMPORT_REQUEST =
  'CREATE_REIMBURSEMENT_IMPORT_REQUEST';
export const CREATE_REIMBURSEMENT_IMPORT_SUCCESS =
  'CREATE_REIMBURSEMENT_IMPORT_SUCCESS';
export const CREATE_REIMBURSEMENT_IMPORT_FAILURE =
  'CREATE_REIMBURSEMENT_IMPORT_FAILURE';
export const PATCH_REIMBURSEMENT_IMPORT_REQUEST =
  'PATCH_REIMBURSEMENT_IMPORT_REQUEST';
export const PATCH_REIMBURSEMENT_IMPORT_SUCCESS =
  'PATCH_REIMBURSEMENT_IMPORT_SUCCESS';
export const PATCH_REIMBURSEMENT_IMPORT_FAILURE =
  'PATCH_REIMBURSEMENT_IMPORT_FAILURE';
export const POST_DOMESTIC_REIMBURSEMENT_ITEM_SUCCESS =
  'POST_DOMESTIC_REIMBURSEMENT_ITEM_SUCCESS';
export const POST_DOMESTIC_REIMBURSEMENT_ITEM_FAILURE =
  'POST_DOMESTIC_REIMBURSEMENT_ITEM_FAILURE';
export const POST_IMPORT_REIMBURSEMENT_ITEM_SUCCESS =
  'POST_IMPORT_REIMBURSEMENT_ITEM_SUCCESS';
export const POST_IMPORT_REIMBURSEMENT_ITEM_FAILURE =
  'POST_IMPORT_REIMBURSEMENT_ITEM_FAILURE';
export const FETCH_REIMBURSEMENTS_REQUEST = 'FETCH_REIMBURSEMENTS_REQUEST';
export const FETCH_REIMBURSEMENTS_SUCCESS = 'FETCH_REIMBURSEMENTS_SUCCESS';
