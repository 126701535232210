import {
  FETCH_FH_FORMS_PACKAGES_REQUEST,
  FETCH_FH_FORMS_PACKAGES_SUCCESS,
  // FETCH_FH_FORMS_PACKAGES_FAILURE,
  FETCH_IMPORTER_FORMS_PACKAGES_REQUEST,
  FETCH_IMPORTER_FORMS_PACKAGES_SUCCESS,
  // FETCH_IMPORTER_FORMS_PACKAGES_FAILURE,
  REFRESH_IMPORTER_FORMS_PACKAGES,
  REFRESH_FH_FORMS_PACKAGES,
  CLEAR_CURRENT_FH_FORMS_PACKAGE,
  CLEAR_CURRENT_FH_FORM,
  CLEAR_CURRENT_IMPORTER_FORMS_PACKAGE,
  CLEAR_CURRENT_IMPORTER_FORM,
  SELECT_CURRENT_IMPORTER_FORMS_PACKAGE,
  SELECT_CURRENT_FH_FORMS_PACKAGE,
  SELECT_CURRENT_IMPORTER_FORM,
  SELECT_CURRENT_FH_FORM,
} from './formsPackageTypes';

const initialState = {
  loading: false,
  initialImporterFormsPackages: false,
  initialFHFormsPackages: false,
  importerFormsPackages: [],
  currentImporterFormsPackage: [],
  currentImporterForm: [],
  fhFormsPackages: [],
  currentFHFormsPackage: [],
  currentFHForm: [],
  error: '',
};

const formsPackageReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FH_FORMS_PACKAGES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_FH_FORMS_PACKAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        initialFHFormsPackages: true,
        fhFormsPackages: action.payload,
        error: '',
      };
    // case FETCH_FH_FORMS_PACKAGES_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     fhFormsPackages: [],
    //     error: action.payload,
    //   };
    case FETCH_IMPORTER_FORMS_PACKAGES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_IMPORTER_FORMS_PACKAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        initialImporterFormsPackages: true,
        importerFormsPackages: action.payload,
        error: '',
      };
    // case FETCH_IMPORTER_FORMS_PACKAGES_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     initialImporterFormsPackages: false,
    //     importerFormsPackages: [],
    //     error: action.payload,
    //   };
    case REFRESH_IMPORTER_FORMS_PACKAGES:
      {
        const storedImporterFormsPackages = localStorage.getItem(
          'importer_forms_packages'
        );
        if (storedImporterFormsPackages) {
          try {
            const importerFormsPackages = JSON.parse(
              storedImporterFormsPackages
            );
            if (importerFormsPackages.length > 0) {
              return {
                ...state,
                importerFormsPackages: importerFormsPackages,
                initialImporterFormsPackages: true,
              };
            } else {
              return {
                ...state,
                importerFormsPackages: [],
                initialImporterFormsPackages: false,
              };
            }
          } catch (e) {}
        }
      }
      break;
    case SELECT_CURRENT_IMPORTER_FORMS_PACKAGE: {
      const importerFormsPackages = state.importerFormsPackages;

      let seasonIndex = 0;
      let packageFound = false;
      let currentImporterFormsPackage = null;
      while (seasonIndex < importerFormsPackages.length && !packageFound) {
        let formsPackageIndex = -1;
        if (formsPackageIndex === -1) {
          formsPackageIndex = importerFormsPackages[seasonIndex]
            .map(function (formsPackage) {
              if (Array.isArray(formsPackage.field_importer_report)) {
                let formIDFound = null;
                formsPackage.field_importer_report.forEach(function (
                  importerReport
                ) {
                  if (importerReport.id === action.formID) {
                    formIDFound = importerReport.id;
                  }
                });
                return formIDFound;
              } else {
                return null;
              }
            })
            .indexOf(action.formID);
        }

        if (formsPackageIndex !== -1) {
          //found it
          packageFound = true;
          currentImporterFormsPackage =
            importerFormsPackages[seasonIndex][formsPackageIndex];
        } else {
          seasonIndex += 1;
        }
      }
      return {
        ...state,
        currentImporterFormsPackage: currentImporterFormsPackage,
      };
    }
    case SELECT_CURRENT_IMPORTER_FORM: {
      const currentImporterFormsPackage = state.currentImporterFormsPackage;

      let currentImporterForm = {};
      if (Array.isArray(currentImporterFormsPackage.field_importer_report)) {
        currentImporterFormsPackage.field_importer_report.forEach(function (
          importerReport
        ) {
          if (importerReport.id === action.formID) {
            currentImporterForm = importerReport;
          }
        });
      }
      return {
        ...state,
        currentImporterForm: currentImporterForm,
      };
    }
    case CLEAR_CURRENT_IMPORTER_FORMS_PACKAGE:
      return {
        ...state,
        currentImporterFormsPackage: [],
      };
    case CLEAR_CURRENT_IMPORTER_FORM:
      return {
        ...state,
        currentImporterForm: [],
      };
    case REFRESH_FH_FORMS_PACKAGES:
      const storedFHFormsPackages = localStorage.getItem('fh_forms_packages');
      let formsPackagesResult = [];
      let formsPackagesInitial = false;
      if (storedFHFormsPackages) {
        const fhFormsPackages = JSON.parse(storedFHFormsPackages);
        if (fhFormsPackages.length > 0) {
          formsPackagesResult = fhFormsPackages;
          formsPackagesInitial = true;
        }
      }
      return {
        ...state,
        fhFormsPackages: formsPackagesResult,
        initialFHFormsPackages: formsPackagesInitial,
      };
    case SELECT_CURRENT_FH_FORMS_PACKAGE: {
      const fhFormsPackages = state.fhFormsPackages;

      let seasonIndex = 0;
      let packageFound = false;
      let currentFHFormsPackage = null;
      while (seasonIndex < fhFormsPackages.length && !packageFound) {
        let formsPackageIndex = -1;
        if (formsPackageIndex === -1) {
          formsPackageIndex = fhFormsPackages[seasonIndex]
            .map(function (formsPackage) {
              if (Array.isArray(formsPackage.field_fh_report)) {
                let formIDFound = null;
                formsPackage.field_fh_report.forEach(function (fhReport) {
                  if (fhReport.id === action.formID) {
                    formIDFound = fhReport.id;
                  }
                });
                return formIDFound;
              } else {
                return null;
              }
            })
            .indexOf(action.formID);
        }

        if (formsPackageIndex !== -1) {
          //found it
          packageFound = true;
          currentFHFormsPackage =
            fhFormsPackages[seasonIndex][formsPackageIndex];
        } else {
          seasonIndex += 1;
        }
      }
      return {
        ...state,
        currentFHFormsPackage: currentFHFormsPackage,
      };
    }
    case SELECT_CURRENT_FH_FORM: {
      const currentFHFormsPackage = state.currentFHFormsPackage;

      let currentFHForm = {};
      if (Array.isArray(currentFHFormsPackage.field_fh_report)) {
        currentFHFormsPackage.field_fh_report.forEach(function (fhReport) {
          if (fhReport.id === action.formID) {
            currentFHForm = fhReport;
          }
        });
      }
      return {
        ...state,
        currentFHForm: currentFHForm,
      };
    }
    case CLEAR_CURRENT_FH_FORMS_PACKAGE:
      return {
        ...state,
        currentFHFormsPackage: [],
      };
    case CLEAR_CURRENT_FH_FORM:
      return {
        ...state,
        currentFHForm: [],
      };
    default:
      return state;
  }
};

export default formsPackageReducer;
