export const POST_FH_NON_EXEMPT_RECEIPT_SUCCESS =
  'POST_FH_NON_EXEMPT_RECEIPT_SUCCESS';
export const POST_FH_NON_EXEMPT_RECEIPT_FAILURE =
  'POST_FH_NON_EXEMPT_RECEIPT_FAILURE';
export const CLEAR_FH_NON_EXEMPT_RECEIPT_ITEMS =
  'CLEAR_FH_NON_EXEMPT_RECEIPT_ITEMS';
export const CLEAR_FH_EXEMPT_RECEIPT_ITEMS = 'CLEAR_FH_EXEMPT_RECEIPT_ITEMS';
export const POST_FH_EXEMPT_RECEIPT_SUCCESS = 'POST_FH_EXEMPT_RECEIPT_SUCCESS';
export const POST_FH_EXEMPT_RECEIPT_FAILURE = 'POST_FH_EXEMPT_RECEIPT_FAILURE';
export const PATCH_FORM_REQUEST = 'PATCH_FORM_REQUEST';
export const PATCH_FORM_SUCCESS = 'PATCH_FORM_SUCCESS';
export const PATCH_FORM_FAILURE = 'PATCH_FORM_FAILURE';
export const AMEND_FORM_REQUEST = 'AMEND_FORM_REQUEST';
export const AMEND_FORM_SUCCESS = 'AMEND_FORM_SUCCESS';
export const AMEND_FORM_FAILURE = 'AMEND_FORM_FAILURE';
