export const FETCH_FH_FORMS_PACKAGES_REQUEST =
  'FETCH_FH_FORMS_PACKAGES_REQUEST';
export const FETCH_FH_FORMS_PACKAGES_SUCCESS =
  'FETCH_FH_FORMS_PACKAGES_SUCCESS';
// export const FETCH_FH_FORMS_PACKAGES_FAILURE =
//   'FETCH_FH_FORMS_PACKAGES_FAILURE';
export const FETCH_IMPORTER_FORMS_PACKAGES_REQUEST =
  'FETCH_IMPORTER_FORMS_PACKAGES_REQUEST';
export const FETCH_IMPORTER_FORMS_PACKAGES_SUCCESS =
  'FETCH_IMPORTER_FORMS_PACKAGES_SUCCESS';
// export const FETCH_IMPORTER_FORMS_PACKAGES_FAILURE =
//   'FETCH_IMPORTER_FORMS_PACKAGES_FAILURE';
export const REFRESH_IMPORTER_FORMS_PACKAGES =
  'REFRESH_IMPORTER_FORMS_PACKAGES';
export const SELECT_CURRENT_IMPORTER_FORMS_PACKAGE =
  'SELECT_CURRENT_IMPORTER_FORMS_PACKAGE';
export const SELECT_CURRENT_IMPORTER_FORM = 'SELECT_CURRENT_IMPORTER_FORM';
export const REFRESH_FH_FORMS_PACKAGES = 'REFRESH_FH_FORMS_PACKAGES';
export const SELECT_CURRENT_FH_FORMS_PACKAGE =
  'SELECT_CURRENT_FH_FORMS_PACKAGE';
export const SELECT_CURRENT_FH_FORM = 'SELECT_CURRENT_FH_FORM';
export const CLEAR_CURRENT_FH_FORMS_PACKAGE = 'CLEAR_CURRENT_FH_FORMS_PACKAGE';
export const CLEAR_CURRENT_FH_FORM = 'CLEAR_CURRENT_FH_FORM';
export const CLEAR_CURRENT_IMPORTER_FORMS_PACKAGE =
  'CLEAR_CURRENT_IMPORTER_FORMS_PACKAGE';
export const CLEAR_CURRENT_IMPORTER_FORM = 'CLEAR_CURRENT_IMPORTER_FORM';
