import {
  FETCH_ALL_EXEMPTIONS_REQUEST,
  FETCH_ALL_EXEMPTIONS_SUCCESS,
  FETCH_ALL_EXEMPTIONS_FAILURE,
  FETCH_EXEMPTIONS_REQUEST,
  FETCH_EXEMPTIONS_SUCCESS,
  CREATE_EXEMPTION_DEMINIMIS_REQUEST,
  CREATE_EXEMPTION_DEMINIMIS_SUCCESS,
  CREATE_EXEMPTION_DEMINIMIS_FAILURE,
  PATCH_EXEMPTION_DEMINIMIS_REQUEST,
  PATCH_EXEMPTION_DEMINIMIS_SUCCESS,
  PATCH_EXEMPTION_DEMINIMIS_FAILURE,
  CREATE_EXEMPTION_ORGANIC_REQUEST,
  CREATE_EXEMPTION_ORGANIC_SUCCESS,
  CREATE_EXEMPTION_ORGANIC_FAILURE,
  PATCH_EXEMPTION_ORGANIC_REQUEST,
  PATCH_EXEMPTION_ORGANIC_SUCCESS,
  PATCH_EXEMPTION_ORGANIC_FAILURE,
} from './exemptionTypes';

import history from '@iso/lib/helpers/history';
import { getAuthClient } from '../../library/drupal/DrupalAuth2';

const auth = getAuthClient();

//Used by contact creation form.  May not be sufficient if paged data
// export const fetchAllExemptions = () => {
//   //fetch all exemptions with status 'approved'
//   const url = `/jsonapi/node/exemption?filter[contact-filter][condition][path]=field_exemption_status&filter[contact-filter][condition][value]=approved`;
//   const options = {
//     method: 'GET',
//     headers: new Headers({
//       Accept: 'application/vnd.api+json',
//       'Content-Type': 'application/vnd.api+json',
//     }),
//     mode: 'cors',
//   };
//   return (dispatch) => {
//     dispatch(fetchAllExemptionsRequest());
//     auth
//       .fetchWithAuthentication(url, options)
//       .then((response) => {
//         return response.json();
//       })
//       .then((data) => {
//         const allExemptions = data;
//         dispatch(fetchAllExemptionsSuccess(allExemptions));
//       })
//       .catch((error) => {
//         const errorMsg = error.message;
//         dispatch(fetchAllExemptionsFailure(errorMsg));
//       });
//   };
// };

export const fetchAllExemptions = (seasons) => {
  return async (dispatch) => {
    dispatch(fetchAllExemptionsRequest());
    let exemptions = [];
    for (const season of seasons) {
      const seasonExemptions = await fetchAllExemptionsBySeason(season.id);
      if (seasonExemptions !== null) {
        //exemptions.push(seasonExemptions.data);
        exemptions[season.id] = seasonExemptions.data;
      }
    }
    dispatch(fetchAllExemptionsSuccess(exemptions));
  };
};

export const fetchAllExemptionsBySeason = async (seasonID) => {
  if (seasonID !== null) {
    //fetch all exemptions with status 'approved' for the given season
    const path = '/jsonapi/node/exemption';
    const filter = `filter[and-group][group][conjunction]=AND&filter[status-filter][condition][path]=field_exemption_status&filter[status-filter][condition][value]=approved&filter[status-filter][condition][memberOf]=and-group&filter[season-filter][condition][path]=field_season.id&filter[season-filter][condition][value]=${seasonID}&filter[season-filter][condition][memberOf]=and-group`;
    const url = `${path}?${filter}`;
    //const include = 'include=field_ex_certificate_pdf,field_ex_application';
    //const url = `${path}?${filter}&${include}`;
    const options = {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
    };
    try {
      const response = await auth.fetchWithAuthentication(url, options);
      if (response.ok) {
        const data = await response.json();
        const exemptionsBySeason = data;
        return exemptionsBySeason;
      } else {
        return null;
      }
    } catch (error) {}
  }
};

export const fetchExemptions = (companyUID, seasons) => {
  return async (dispatch) => {
    dispatch(fetchExemptionsRequest());
    let exemptions = [];
    for (const season of seasons) {
      const seasonExemptions = await fetchExemptionsBySeason(
        companyUID,
        season.id
      );
      if (seasonExemptions !== null) {
        exemptions.push(seasonExemptions.data);
      }
    }
    dispatch(fetchExemptionsSuccess(exemptions));
  };
};

export const fetchExemptionsBySeason = async (companyUID, seasonID) => {
  if (companyUID !== null) {
    const path = '/jsonapi/node/exemption';
    const filter = `filter[and-group][group][conjunction]=AND&filter[company-filter][condition][path]=field_appb_company.id&filter[company-filter][condition][value]=${companyUID}&filter[company-filter][condition][memberOf]=and-group&filter[season-filter][condition][path]=field_season.id&filter[season-filter][condition][value]=${seasonID}&filter[season-filter][condition][memberOf]=and-group`;
    const include = 'include=field_ex_certificate_pdf,field_ex_application';
    const url = `${path}?${filter}&${include}`;
    const options = {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
    };
    try {
      const response = await auth.fetchWithAuthentication(url, options);
      if (response.ok) {
        const data = await response.json();
        const exemptionsBySeason = data;
        return exemptionsBySeason;
      } else {
        return null;
      }
    } catch (error) {}
  }
};

export const createExemptionDeminimis = (data) => {
  const url = `/jsonapi/node/ex_request_de_minimis`;
  const options = {
    method: 'POST',
    headers: new Headers({
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
    mode: 'cors',
    body: JSON.stringify(data),
  };
  return async (dispatch) => {
    dispatch(createExemptionDeminimisRequest());
    try {
      const response = await auth.fetchWithAuthentication(url, options);
      if (response.ok) {
        dispatch(createExemptionDeminimisSuccess(JSON.stringify(response)));
        const data = await response.json();
        const newFormID = data.data.id;
        history.push(
          `/dashboard/formCertification/ex_request_de_minimis/${newFormID}`
        );
      } else {
        dispatch(createExemptionDeminimisFailure('An error has occured.'));
      }
    } catch (error) {
      const errorMsg = error.message;
      dispatch(createExemptionDeminimisFailure(errorMsg));
    }
  };
};

export const patchExemptionDeminimis = (formUID, data) => {
  const url = `/jsonapi/node/ex_request_de_minimis/${formUID}`;
  const options = {
    method: 'PATCH',
    headers: new Headers({
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
    mode: 'cors',
    body: JSON.stringify(data),
  };
  return async (dispatch) => {
    dispatch(patchExemptionDeminimisRequest());
    try {
      const response = await auth.fetchWithAuthentication(url, options);
      if (response.ok) {
        dispatch(patchExemptionDeminimisSuccess(JSON.stringify(response)));
        const data = await response.json();
        const formID = data.data.id;
        history.push(
          `/dashboard/formCertification/ex_request_de_minimis/${formID}`
        );
      } else {
        dispatch(patchExemptionDeminimisFailure('An error has occured.'));
      }
    } catch (error) {
      const errorMsg = error.message;
      dispatch(patchExemptionDeminimisFailure(errorMsg));
    }
  };
};

export const createExemptionOrganic = (data) => {
  const url = `/jsonapi/node/ex_request_organic`;
  const options = {
    method: 'POST',
    headers: new Headers({
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
    mode: 'cors',
    body: JSON.stringify(data),
  };
  return async (dispatch) => {
    dispatch(createExemptionOrganicRequest());
    try {
      const response = await auth.fetchWithAuthentication(url, options);
      if (response.ok) {
        dispatch(createExemptionOrganicSuccess(JSON.stringify(response)));
        const data = await response.json();
        const newFormID = data.data.id;
        history.push(
          `/dashboard/formCertification/ex_request_organic/${newFormID}`
        );
      } else {
        dispatch(createExemptionOrganicFailure('An error has occured.'));
      }
    } catch (error) {
      const errorMsg = error.message;
      dispatch(createExemptionOrganicFailure(errorMsg));
    }
  };
};

export const patchExemptionOrganic = (formUID, data) => {
  const url = `/jsonapi/node/ex_request_organic/${formUID}`;
  const options = {
    method: 'PATCH',
    headers: new Headers({
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
    mode: 'cors',
    body: JSON.stringify(data),
  };
  return async (dispatch) => {
    dispatch(patchExemptionOrganicRequest());
    try {
      const response = await auth.fetchWithAuthentication(url, options);
      if (response.ok) {
        dispatch(patchExemptionOrganicSuccess(JSON.stringify(response)));
        const data = await response.json();
        const formID = data.data.id;
        history.push(
          `/dashboard/formCertification/ex_request_organic/${formID}`
        );
      } else {
        dispatch(patchExemptionOrganicFailure('An error has occured.'));
      }
    } catch (error) {
      const errorMsg = error.message;
      dispatch(patchExemptionOrganicFailure(errorMsg));
    }
  };
};

export const postExemptionOrganicFileItem = (file, fileName) => {
  // Maintaining this as a redux action, however, not using redux state.  Just return the file upload response
  // const url = `/jsonapi/node/ex_request_organic/${formUID}/field_organic_documentaion_pdf`;
  const url = `/jsonapi/node/ex_request_organic/field_organic_documentaion_pdf`;
  const options = {
    method: 'POST',
    headers: new Headers({
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/octet-stream',
      'Content-Disposition': `file; filename="${fileName}"`,
    }),
    mode: 'cors',
    body: file,
  };
  return async (dispatch) => {
    //dispatch(postExemptionOrganicFileItemRequest());
    try {
      const response = await auth.fetchWithAuthentication(url, options);
      if (response.ok) {
        //dispatch(postExemptionOrganicFileItemSuccess(JSON.stringify(response)));
        //console.log('FILE SUCCESS', response);
        const data = await response.json();
        return data.data;
      } else {
        //console.log('FILE FAILURE', response);
        //dispatch(postExemptionOrganicFileItemFailure('An error has occured.'));
        return false;
      }
    } catch (error) {
      //console.log('FILE ERROR');
      //const errorMsg = error.message;
      //dispatch(postExemptionOrganicFileItemFailure(errorMsg));
      return false;
    }
  };
};

export const fetchAllExemptionsRequest = () => {
  return {
    type: FETCH_ALL_EXEMPTIONS_REQUEST,
  };
};

export const fetchAllExemptionsSuccess = (allExemptions) => {
  return {
    type: FETCH_ALL_EXEMPTIONS_SUCCESS,
    payload: allExemptions,
  };
};

export const fetchAllExemptionsFailure = (error) => {
  return {
    type: FETCH_ALL_EXEMPTIONS_FAILURE,
    payload: error,
  };
};

export const fetchExemptionsRequest = () => {
  return {
    type: FETCH_EXEMPTIONS_REQUEST,
  };
};

export const fetchExemptionsSuccess = (exemptions) => {
  return {
    type: FETCH_EXEMPTIONS_SUCCESS,
    payload: exemptions,
  };
};

export const createExemptionDeminimisRequest = () => {
  return {
    type: CREATE_EXEMPTION_DEMINIMIS_REQUEST,
  };
};

export const createExemptionDeminimisSuccess = (success) => {
  return {
    type: CREATE_EXEMPTION_DEMINIMIS_SUCCESS,
    payload: success.data,
  };
};

export const createExemptionDeminimisFailure = (error) => {
  return {
    type: CREATE_EXEMPTION_DEMINIMIS_FAILURE,
    payload: error,
  };
};

export const patchExemptionDeminimisRequest = () => {
  return {
    type: PATCH_EXEMPTION_DEMINIMIS_REQUEST,
  };
};

export const patchExemptionDeminimisSuccess = (success) => {
  return {
    type: PATCH_EXEMPTION_DEMINIMIS_SUCCESS,
    payload: success.data,
  };
};

export const patchExemptionDeminimisFailure = (error) => {
  return {
    type: PATCH_EXEMPTION_DEMINIMIS_FAILURE,
    payload: error,
  };
};

export const createExemptionOrganicRequest = () => {
  return {
    type: CREATE_EXEMPTION_ORGANIC_REQUEST,
  };
};

export const createExemptionOrganicSuccess = (success) => {
  return {
    type: CREATE_EXEMPTION_ORGANIC_SUCCESS,
    payload: success.data,
  };
};

export const createExemptionOrganicFailure = (error) => {
  return {
    type: CREATE_EXEMPTION_ORGANIC_FAILURE,
    payload: error,
  };
};

export const patchExemptionOrganicRequest = () => {
  return {
    type: PATCH_EXEMPTION_ORGANIC_REQUEST,
  };
};

export const patchExemptionOrganicSuccess = (success) => {
  return {
    type: PATCH_EXEMPTION_ORGANIC_SUCCESS,
    payload: success.data,
  };
};

export const patchExemptionOrganicFailure = (error) => {
  return {
    type: PATCH_EXEMPTION_ORGANIC_FAILURE,
    payload: error,
  };
};
